import { Box } from "./index";
import * as React from "react";

interface Props {
    enabled: boolean;
}

const Dot = ({ enabled }: Props) => (
    <Box sx={{
        display: "inline-block",
        width: "10px",
        height: "10px",
        backgroundColor: enabled ? "#77D692" : "#000000",
        borderRadius: "20px",
    }} />
);

export default Dot;
