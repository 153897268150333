import { Alert, Box, Button, IconButton, InputAdornment, TextField } from "@mui/material";
import Logo from "../components/Logo";
import React, { SyntheticEvent, useContext, useState } from "react";
import AuthContext from "../contexts/AuthContext";
import { EyeIcon, EyeOffIcon } from "@heroicons/react/solid";

const Page = () => {
    const [ state, setState ] = useState(
        {} as { username?: string; password?: string }
    );

    const { login } = useContext(AuthContext);
    const [ showPassword, setShowPassword ] = useState(false);
    const [ error, setError ] = useState<string | undefined>();

    const handleLogin = async (e: SyntheticEvent) => {
        setError(undefined);
        e.preventDefault();
        try {
            await login(state.username!, state.password!);
        } catch (e) {
            setError("Invalid credentials");
        }
    };

    return (
        <Box
            sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                background: "#000000",
                height: "100vh",
            }}
        >
            <Box
                sx={{
                    width: "560px",
                    background: "#252525",
                    color: "#FFFFFF",
                    padding: "24px",
                    borderRadius: "4px",
                    ".MuiFilledInput-input": {
                        backgroundColor: "#3C3C3C",
                        borderRadius: "4px",
                        color: "white",
                    },
                    ".MuiInputLabel-root": {
                        color: "#FFFFFF",
                    },
                    ".MuiInputLabel-root.Mui-focused": {
                        color: "rgba(255, 255, 255, 0.56)",
                    },
                }}
            >
                <Box
                    sx={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        height: "60px",
                        fill: "white",
                        lineHeight: "40px",
                        fontWeight: 600,
                        fontSize: "16px",
                    }}
                >
                    <Logo />
                    Campaign Manager
                </Box>

                <Box component="form" noValidate sx={{ mt: 1 }}>
                    <TextField
                        sx={{
                            backgroundColor: "#444444",
                            borderRadius: "4px",
                            "input:-webkit-autofill": {
                                WebkitAnimationDelay: "1s",
                                WebkitAnimationName: "autofill",
                                WebkitAnimationFillMode: "both",
                            },
                            "input, .MuiFilledInput-root": {
                                background: "#444444!important",
                            }
                        }}
                        variant="filled"
                        margin="normal"
                        fullWidth
                        id="username"
                        label="Username"
                        autoFocus
                        onChange={(e) =>
                            setState({ ...state, username: e.target.value })
                        }
                    />
                    <TextField
                        sx={{
                            backgroundColor: "#444444",
                            borderRadius: "4px",
                            "input:-webkit-autofill": {
                                WebkitAnimationDelay: "1s",
                                WebkitAnimationName: "autofill",
                                WebkitAnimationFillMode: "both",
                            },
                            "input, .MuiFilledInput-root": {
                                background: "#444444!important",
                            }
                        }}
                        variant="filled"
                        margin="normal"
                        fullWidth
                        label="Password"
                        type={showPassword ? "text" : "password"}
                        id="password"
                        autoComplete="current-password"
                        onChange={(e) =>
                            setState({ ...state, password: e.target.value })
                        }
                        InputProps={{
                            endAdornment: (
                                <InputAdornment
                                    position="end">
                                    <IconButton
                                        onClick={() => setShowPassword(!showPassword)}
                                        sx={{
                                            color: "white",
                                            background: "transparent",
                                        }}
                                    >
                                        {showPassword ? <EyeIcon width={20} /> : <EyeOffIcon width={20} />}
                                    </IconButton>
                                </InputAdornment>
                            )
                        }}
                    />

                    {error &&
                      <Alert
                          severity="error"
                          sx={{
                              margin: "20px 0 10px 0"
                          }}
                      >{error}</Alert>
                    }

                    <Button
                        type="submit"
                        fullWidth
                        variant="contained"
                        sx={{
                            mt: 3,
                            mb: 2,
                            textTransform: "none",
                            lineHeight: "2.5em",
                            fontWeight: 500,
                            fontSize: "16px",
                        }}
                        onClick={handleLogin}
                    >
                        Log in
                    </Button>
                </Box>
            </Box>
        </Box>
    );
};

export default Page;
