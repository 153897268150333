import { Box, BoxProps, styled } from "@mui/material";
import * as React from "react";

const Component = styled(Box)<BoxProps>(_ => ({
    border: "1px solid #EAEAEA",
    borderRadius: "4px",
    background: "#FFFFFF",
    padding: "24px",
}))

export default Component;
