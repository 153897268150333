import Logo from "../components/Logo";
import { useContext, useEffect, useState } from "react";
import AuthContext from "../contexts/AuthContext";
import CampaignContext from "../contexts/CampaignContext";
import {
    FilmIcon,
    HomeIcon,
    TagIcon,
    TemplateIcon,
} from "@heroicons/react/solid";
import { Link, useNavigate, useParams, Outlet } from "react-router-dom";
import { getCampaigns } from "../common/api";
import {
    CampaignList,
    AppBar,
    Box,
    Button,
    List,
    ListItem,
    ListItemButton,
    ListItemIcon,
    ListItemText,
    Menu,
    MenuItem,
    Popover,
    Toolbar,
    Typography,
} from "../components";

const CampaignNav = () => {
    const { campaign } = useContext(CampaignContext);

    if (campaign === undefined) {
        return null;
    }

    return (
        <List
            disablePadding={true}
            sx={{
                display: "flex",
                flexDirection: "row",
                padding: 0,
                backgroundColor: "#282828",
                ".MuiListItemText-root > span": {
                    fontSize: "14px",
                },
                ".MuiListItemIcon-root": {
                    minWidth: "24px",
                    "& > svg": {
                        fill: "white",
                        height: "16px",
                    },
                },
                ".MuiListItem-root": {
                    flex: 0,
                    whiteSpace: "nowrap",
                    "&:hover": {
                        background: "#FFFFFF22",
                    },
                },
            }}
        >
            <ListItem disablePadding>
                <ListItemButton
                    component={Link}
                    to={`/campaigns/${campaign.id}/dashboard`}
                >
                    <ListItemIcon>
                        <HomeIcon />
                    </ListItemIcon>
                    <ListItemText primary="Dashboard" />
                </ListItemButton>
            </ListItem>
            <ListItem disablePadding>
                <ListItemButton component={Link} to={`/campaigns/${campaign.id}/productions`}>
                    <ListItemIcon>
                        <TemplateIcon />
                    </ListItemIcon>
                    <ListItemText primary="Productions" />
                </ListItemButton>
            </ListItem>
            <ListItem disablePadding>
                <ListItemButton component={Link} to={`/campaigns/${campaign.id}/products`}>
                    <ListItemIcon>
                        <TagIcon />
                    </ListItemIcon>
                    <ListItemText primary="Products" />
                </ListItemButton>
            </ListItem>
            <ListItem disablePadding>
                <ListItemButton component={Link} to={`/campaigns/${campaign.id}/cta`}>
                    <ListItemIcon>
                        <FilmIcon />
                    </ListItemIcon>
                    <ListItemText primary="Player CTA" />
                </ListItemButton>
            </ListItem>
        </List>
    );
}

const Top = () => {
    const { user, customer, logout } = useContext(AuthContext);
    const { campaign } = useContext(CampaignContext);
    const navigate = useNavigate();

    const [ profileAnchor, setProfileAnchor ] = useState(
        undefined as Element | undefined
    );
    const [ campaignSelectorAnchor, setCampaignSelectorAnchor ] = useState(
        undefined as Element | undefined
    );

    if (user === undefined || customer === undefined) {
        return null;
    }

    return (
        <AppBar position="relative">
            <Toolbar
                sx={{
                    backgroundColor: "#000000",
                    height: "84px",
                }}
            >
                <Logo fill="#FFFFFF" height={23} />
                <Box
                    sx={{
                        position: "relative",
                        borderLeft: "1px solid #666666",
                        width: "30px",
                        height: "30px",
                        left: "10px",
                    }}
                />
                <Box sx={{
                    flexShrink: "100px",
                    height: "56px",
                    borderRadius: "4px",
                    padding: "6px 8px",
                    "&:hover": {
                        background: "#333333",
                    }
                }}>
                    <Typography
                        sx={{
                            color: "#a3a3a3",
                            fontSize: "14px",
                            lineHeight: 1.3,
                        }}
                    >
                      Campaign
                    </Typography>
                    <Typography
                        sx={{
                            fontSize: "16px",
                            lineHeight: 1.3,
                            cursor: "pointer",
                        }}
                        onClick={(e) =>
                            setCampaignSelectorAnchor(e.currentTarget)
                        }
                    >
                        {campaign?.name ?? "Select Campaign"}
                      &nbsp;
                        <svg
                            width="11"
                            height="7"
                            viewBox="0 0 11 7"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path
                                fillRule="evenodd"
                                clipRule="evenodd"
                                d="M0.293 1.29299C0.480528 1.10552 0.734836 1.0002 1 1.0002C1.26516 1.0002 1.51947 1.10552 1.707 1.29299L5 4.58599L8.293 1.29299C8.38525 1.19748 8.49559 1.1213 8.6176 1.06889C8.7396 1.01648 8.87082 0.988893 9.0036 0.987739C9.13638 0.986585 9.26806 1.01189 9.39095 1.06217C9.51385 1.11245 9.6255 1.1867 9.71939 1.28059C9.81329 1.37449 9.88754 1.48614 9.93782 1.60904C9.9881 1.73193 10.0134 1.86361 10.0123 1.99639C10.0111 2.12917 9.98351 2.26039 9.9311 2.38239C9.87869 2.5044 9.80251 2.61474 9.707 2.70699L5.707 6.70699C5.51947 6.89446 5.26516 6.99978 5 6.99978C4.73484 6.99978 4.48053 6.89446 4.293 6.70699L0.293 2.70699C0.105529 2.51946 0.000213623 2.26515 0.000213623 1.99999C0.000213623 1.73483 0.105529 1.48052 0.293 1.29299Z"
                                fill="white"
                            />
                        </svg>
                    </Typography>
                    <Popover
                        open={campaignSelectorAnchor !== undefined}
                        onClose={() =>
                            setCampaignSelectorAnchor(undefined)
                        }
                        anchorEl={campaignSelectorAnchor}
                        anchorOrigin={{
                            horizontal: "left",
                            vertical: "bottom",
                        }}
                    >
                        <CampaignList onSelect={campaign => {
                            navigate(`/campaigns/${campaign.id}`);
                            setCampaignSelectorAnchor(undefined);
                        }} />
                    </Popover>
                </Box>
                <Box sx={{ flexGrow: 1 }}></Box>
                <Button
                    sx={{
                        borderRadius: "50%",
                        backgroundColor: "#0052CC",
                        width: "32px",
                        height: "32px",
                        minWidth: "auto",
                        color: "#FFFFFF",
                        padding: "0",
                        "&:hover": {
                            backgroundColor: "#0052CC",
                        },
                    }}
                    onClick={(e) => setProfileAnchor(e.currentTarget)}
                >{(user.display_name ?? user.name).substring(0, 1).toUpperCase()}</Button>
                <Menu
                    open={profileAnchor !== undefined}
                    anchorEl={profileAnchor}
                    onClose={() => setProfileAnchor(undefined)}
                    sx={{
                        "& > .MuiPaper-root": {
                            width: "250px",
                        },
                    }}
                >
                    <ListItem sx={{
                        display: "flex",
                    }}>
                        <Box
                            sx={{
                                display: "inline-block",
                                borderRadius: "50%",
                                backgroundColor: "#0052CC",
                                padding: "8px 16px",
                                minWidth: "auto",
                                color: "#FFFFFF",
                                marginRight: "8px",
                            }}
                        >{(user.display_name ?? user.name).substring(0, 1).toUpperCase()}</Box>
                        <Box>
                            <Typography sx={{
                                fontSize: "16px",
                                fontWeight: 500
                            }}>{user.display_name ?? user.name}</Typography>
                            <Typography sx={{
                                fontSize: "14px",
                                fontWeight: 500,
                                color: "#666666",
                            }}>{customer.display_name ?? customer.name}</Typography>
                        </Box>
                    </ListItem>
                    <MenuItem
                        onClick={() => {
                            setProfileAnchor(undefined);
                        }}
                    >Invoices</MenuItem>
                    <MenuItem
                        onClick={() => {
                            setProfileAnchor(undefined);
                        }}
                    >Campaign reports</MenuItem>
                    <MenuItem
                        onClick={() => {
                            setProfileAnchor(undefined);
                        }}
                    >Manual</MenuItem>
                    <MenuItem
                        onClick={() => {
                            setProfileAnchor(undefined);
                        }}
                    >FAQ</MenuItem>
                    <MenuItem
                        onClick={() => {
                            setProfileAnchor(undefined);
                            logout();
                        }}
                    >Log out</MenuItem>
                </Menu>
            </Toolbar>
            {campaign && <CampaignNav />}
        </AppBar>
    );
}

const DefaultLayout = ({ children }: any) => {
    const { customer } = useContext(AuthContext);
    const { activate } = useContext(CampaignContext);
    const { cid } = useParams();

    useEffect(() => {
        if (cid !== undefined) {
            getCampaigns().then(campaigns => activate(campaigns.find(x => x.id === parseInt(cid))));
        } else {
            activate(undefined);
        }
    }, [ cid ]);

    return (
        <Box sx={{
            display: "grid",
            overflow: "hidden",
            gridTemplateRows: "min-content 1fr",
            height: "100vh"
        }}>
            {customer && <Top />}
            <Box sx={{
                overflowY: "auto",
                marginBottom: "24px",
            }}>
                <Outlet />
            </Box>
        </Box>
    );
};

export default DefaultLayout;
