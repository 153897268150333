import { useContext, useEffect, useState } from "react";
import { Link as RouterLink, useNavigate, useParams } from "react-router-dom";
import { ChevronRightIcon, PlusIcon, StarIcon } from "@heroicons/react/solid";
import CampaignContext from "../../../../contexts/CampaignContext";
import {
    createDesign,
    getDesigns,
    getProduction,
    getTemplates,
    saveDesign,
    saveProduction
} from "../../../../common/api";
import { Campaign, Design, Production, Rule, Template } from "../../../../common/types";
import {
    Heading, Table, Breadcrumbs,
    Box,
    Button,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    Typography,
    Link, TextField, MenuItem, Select, SimpleDialog, Dot, IconButton
} from "../../../../components";
import * as React from "react";
import { Switch } from "@mui/material";
import RuleEditor from "./rules/editor";

const CreateDesign = ({ campaign, open, onClose }: { campaign?: Campaign, open: boolean, onClose?: () => void }) => {
    const [ template, setTemplate ] = useState<Template | undefined>();
    const [ templates, setTemplates ] = useState<Template[]>([]);
    const [ name, setName ] = useState("")
    const { pid } = useParams();
    const navigate = useNavigate();
    const productionId = parseInt(pid ?? "");

    useEffect(() => {
        if (campaign) {
            getTemplates(campaign.id).then(setTemplates);
        }
    }, [ campaign ]);

    return (
        <>
            <SimpleDialog open={open} title="Create a new design" onClose={onClose}>
                <Box sx={{
                    border: "1px solid #EAEAEA",
                    borderRadius: "4px",
                    padding: "24px",
                }}>
                    <Typography sx={{
                        fontSize: "20px",
                        fontWeight: "600",
                        color: "#000000",
                    }}>
                  Name
                    </Typography>

                    <Typography sx={{
                        fontSize: "14px",
                        marginTop: "8px",
                        color: "#666666",
                    }}>
                  Nam scelerisque pellentesque ante, id cursus nisl bibendum vel. Integer ac venenatis erat. Integer sollicitudin elementum ex eget imperdiet. Vestibulum sed cursus mauris.
                    </Typography>

                    <TextField
                        sx={{
                            marginTop: "16px",
                        }}
                        value={name}
                        onChange={e => setName(e.target.value)}
                        fullWidth />
                </Box>
                <Box sx={{
                    border: "1px solid #EAEAEA",
                    borderRadius: "4px",
                    padding: "24px",
                    marginTop: "24px",
                }}>
                    <Typography sx={{
                        fontSize: "20px",
                        fontWeight: "600",
                        color: "#000000",
                    }}>
                  Choose template
                    </Typography>

                    <Typography sx={{
                        fontSize: "14px",
                        marginTop: "8px",
                        color: "#666666",
                    }}>
                  Nam scelerisque pellentesque ante, id cursus nisl bibendum vel. Integer ac venenatis erat. Integer sollicitudin elementum ex eget imperdiet. Vestibulum sed cursus mauris.
                    </Typography>

                    <Select
                        fullWidth
                        value={template?.id ?? 0}
                        onChange={e => setTemplate(templates.find(t => t.id === e.target.value))}
                        sx={{
                            marginTop: "24px",
                        }}
                    >
                        {templates.map(t => (
                            <MenuItem key={t.id} value={t.id}>{t.name}</MenuItem>
                        ))}
                    </Select>
                </Box>
                <Box>
                    <Button
                        variant="contained"
                        onClick={() => {
                            createDesign(productionId, template!.id, name).then(design => navigate(`/campaigns/${campaign?.id}/productions/${productionId}/designs/${design.id}`));
                        }}
                        sx={{
                            marginTop: "24px",
                        }}
                    >
                  Create
                    </Button>
                </Box>
            </SimpleDialog>
        </>
    );
};

const Page = () => {
    const { campaign } = useContext(CampaignContext);
    const [ designs, setDesigns ] = useState<Design[] | undefined>();
    const [ production, setProduction ] = useState<Production | undefined>();
    const [ open, setOpen ] = useState(false);
    const [ editRule, setEditRule ] = useState<Rule | undefined>();
    const { pid } = useParams();
    const navigate = useNavigate()

    useEffect(() => {
        getProduction(parseInt(pid!)).then(setProduction);
        getDesigns(parseInt(pid!)).then(setDesigns);
    }, [ campaign, pid ]);

    return (
        <>
            <SimpleDialog
                open={editRule !== undefined}
                title={`Update ${editRule?.name}`}
                onClose={() => setEditRule(undefined)}>
                <RuleEditor
                    onClose={() => {
                        setEditRule(undefined);
                        getDesigns(parseInt(pid!)).then(setDesigns);
                    }}
                    ruleId={editRule?.id}
                />
            </SimpleDialog>

            <Breadcrumbs aria-label="breadcrumb">
                <Link underline="hover" color="inherit" to="/campaigns" component={RouterLink} >
                    Campaigns
                </Link>
                <Link underline="hover" color="inherit" to={`/campaigns/${campaign?.id}/productions`} component={RouterLink} >
                    Productions
                </Link>
                <Typography color="text.primary">{production?.name}</Typography>
            </Breadcrumbs>

            <Typography sx={{
                marginTop: "25px",
                float: "right"
            }}>
                <CreateDesign campaign={campaign} open={open} onClose={() => setOpen(false)} />
            </Typography>

            <Heading>
                {production?.name}
            </Heading>

            <Box
                sx={{
                    background: "white",
                    padding: "24px",
                    border: "1px solid #EAEAEA",
                    borderRadius: "4px",
                }}
            >
                <Box sx={{ marginBottom: "40px" }}>
                    <Button
                        variant="contained"
                        sx={{
                            backgroundColor: "#000000",
                            "&:hover": {
                                backgroundColor: "#000000"
                            }
                        }}
                    >
                        Designs
                    </Button>

                    <Button
                        variant="contained"
                        sx={{
                            marginLeft: "10px",
                            backgroundColor: "#00000014",
                            color: "#000000",
                            "&:hover": {
                                backgroundColor: "#00000030",
                                color: "#000000",
                            }
                        }}
                        onClick={() => navigate("rules")}
                    >
                        Display Rules
                    </Button>

                    <Button
                        variant="contained"
                        sx={{
                            float: "right",
                        }}
                        onClick={() => setOpen(true)}
                        startIcon={<PlusIcon height={24} />}
                    >
                        Create
                    </Button>
                </Box>


                <Table
                    sx={{
                        "td, th": {
                            whiteSpace: "nowrap",
                        },
                        "td:last-child": {
                            width: "100%",
                        },
                        "thead > tr > th": {
                            fontWeight: 600,
                        },
                        "tbody > tr > td": {
                            fontWeight: 500,
                        },
                    }}>
                    <TableHead>
                        <TableRow>
                            <TableCell width={300}>Name</TableCell>
                            <TableCell>Template</TableCell>
                            <TableCell>Active</TableCell>
                            <TableCell>Display rules</TableCell>
                            <TableCell>Default</TableCell>
                            <TableCell>&nbsp;</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {designs?.map((design) => (
                            <TableRow key={design.id}>
                                <TableCell>{design.name}</TableCell>
                                <TableCell>{design.template_name}</TableCell>
                                <TableCell>
                                    <Switch
                                        checked={design.is_active} onChange={async e => {
                                            await saveDesign({
                                                id: design.id,
                                                is_active: e.target.checked
                                            });
                                            setDesigns(await getDesigns(parseInt(pid!)));
                                        }} />
                                </TableCell>
                                <TableCell>
                                    {design.rules!.map(rule => (
                                        <Box
                                            sx={{
                                                display: "inline-block",
                                                padding: "4px 8px",
                                                marginRight: "8px",
                                                background: "#00F3B9",
                                                borderRadius: "4px",
                                                color: "black",
                                                cursor: "pointer",
                                            }}
                                            onClick={() => setEditRule(rule)}
                                        >{rule.name}</Box>
                                    ))}
                                </TableCell>
                                <TableCell>
                                    {design.is_default
                                        ? (
                                            <IconButton>
                                                <StarIcon height={20} color="#F5A623" />
                                            </IconButton>
                                        )
                                        : (
                                            <IconButton onClick={async () => {
                                                await saveProduction({
                                                    id: parseInt(pid!),
                                                    default_design_id: design.id
                                                });
                                                setDesigns(await getDesigns(parseInt(pid!)));
                                            }}>
                                                <StarIcon height={20} color="#999999" />
                                            </IconButton>
                                        )}
                                </TableCell>
                                <TableCell align="right">
                                    <IconButton
                                        sx={{ background: "#FAFAFA" }}
                                        component={RouterLink}
                                        to={`${design.id}`}>
                                        <ChevronRightIcon color="black" height={20} />
                                    </IconButton>
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </Box>
        </>
    );
};

export default Page;
