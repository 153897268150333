import { useContext, useEffect, useRef, useState } from "react";
import {
    Button,
    Spinner,
    TextField,
    Box,
    MenuItem,
    Select,
    styled,
    Typography,
    IconButton
} from "../../../../../components";
import { Design, Rule, RuleIdentifier, RuleQueryCount } from "../../../../../common/types";
import CampaignContext from "../../../../../contexts/CampaignContext";
import {
    countRules,
    createRule,
    getDesigns,
    getIdentifiers,
    getRule,
    queryAgg,
    saveRule
} from "../../../../../common/api";
import { CheckIcon, ExclamationCircleIcon, PencilIcon } from "@heroicons/react/solid";
import { useParams } from "react-router-dom";
import {
    Condition,
    StringCondition,
    OptionCondition,
    StringOp,
    Editor,
    parseCondition,
    FloatCondition, FloatOp
} from "./conditions";
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from "@mui/material";

const Section = styled(Box)(_ => ({
    border: "1px solid #EAEAEA",
    borderRadius: "8px",
    padding: "16px 16px",
    display: "flex",
    flexWrap: "wrap",
    "&:not(:last-child)": {
        marginBottom: "24px",
    }
}));

interface StepProps {
    children?: any[];
    title: string;
    subtitle: string;
    description?: string;
    expanded: boolean;
    expandable?: boolean;
    visible?: boolean;
    onEdit?: () => void;
}

const Step = ({
    title,
    subtitle,
    description,
    expanded,
    children,
    visible,
    onEdit,
}: StepProps): JSX.Element | null => {
    if (!(visible ?? true)) {
        return null;
    }

    if (!expanded) {
        return (
            <Section>
                <Typography sx={{
                    fontSize: "20px",
                    color: "#000000",
                    fontWeight: 600,
                    flexGrow: 1,
                }}>{title}</Typography>

                { onEdit && (
                    <IconButton
                        onClick={() => {}}
                        sx={{
                            background: "#FAFAFA",
                        }}>
                        <PencilIcon color="black" height={20} onClick={onEdit}/>
                    </IconButton>
                )}

                <Box sx={{
                    borderRadius: "80px",
                    background: "#50E3C2",
                    display: "inline-block",
                    width: "32px",
                    height: "32px",
                    padding: "6px",
                    margin: "0 0 0 16px",
                }}>
                    <CheckIcon color="#FFFFFF" height={20} />
                </Box>

            </Section>
        )
    }

    return (
        <Section sx={{
            "& > *": {
                margin: "8px!important",
            }
        }}>
            <Typography sx={{
                fontSize: "12px",
                color: "#888888",
                fontWeight: 500,
                flex: "0 0 100%",
            }}>{title}</Typography>

            <Typography sx={{
                fontSize: "20px",
                color: "#000000",
                fontWeight: 600,
                flex: "0 0 100%",
            }}>{subtitle}</Typography>

            { description && (
                <Typography sx={{
                    fontSize: "12px",
                    color: "#888888",
                    fontWeight: 500,
                    flex: "0 0 100%",
                }}>{description}</Typography>
            )}

            {children}
        </Section>
    );
}

interface ComponentProps {
    ruleId?: number;
    onClose?: () => void;
}


const ConfirmDeletionDialog = () => {
    const [ open, setOpen ] = useState(false);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    return (
        <div>
            <Button variant="outlined" onClick={handleClickOpen}>
              Open alert dialog
            </Button>
            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    {"Use Google's location service?"}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        Let Google help apps determine location. This means sending anonymous
                        location data to Google, even when no apps are running.
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose}>Disagree</Button>
                    <Button onClick={handleClose} autoFocus>
                      Agree
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}


const Component = ({ onClose, ruleId }: ComponentProps) => {
    const [ design, setDesign ] = useState<Design | undefined>();
    const designRef = useRef<HTMLSelectElement>();
    const { campaign } = useContext(CampaignContext);
    const [ designs, setDesigns ] = useState([] as any[]);
    const [ loading, setLoading ] = useState(false);
    const [ identifiers, setIdentifiers ] = useState<Record<string, RuleIdentifier>>({});
    const [ name, setName ] = useState("");
    const [ identifier, setIdentifier ] = useState("");
    const [ result, setResult ] = useState<RuleQueryCount | undefined | false>();
    const [ step, setStep ] = useState("condition");
    const [ condition, setCondition ] = useState<Condition | undefined>();
    const [ error, setError ] = useState<string | undefined>();
    const [ deleteRuleId, setDeleteRuleId ] = useState<number | undefined>();
    const { pid } = useParams();

    useEffect(() => {
        (async () => {
            if (ruleId === undefined) {
                return;
            }

            try {
                const rule = await getRule(ruleId);

                console.log("loaded rule", ruleId, rule);

                setName(rule?.name ?? "");

                if ((rule?.expression ?? "").trim() != "") {
                    const condition = parseCondition(rule!.expression!);
                    setCondition(condition);
                    setIdentifier(condition?.identifier ?? "");
                }
            } catch {
                setError("This display rule could not be loaded");
            }
        })();
    }, [ ruleId ])

    useEffect(() => {
        getIdentifiers().then(setIdentifiers);
        getDesigns(parseInt(pid!)).then(setDesigns);
    }, [ campaign ]);

    if (error) {
        return (
            <>
                <Box sx={{
                    border: "1px solid #EAEAEA",
                    borderRadius: "8px",
                    padding: "24px",
                    textAlign: "center",
                }}>
                    <Box sx={{
                        borderRadius: "80px",
                        background: "red",
                        display: "inline-block",
                        width: "80px",
                        height: "80px",
                        padding: "20px",
                    }}>
                        <ExclamationCircleIcon color="#FFFFFF" height={40} />
                    </Box>

                    <Typography sx={{
                        fontSize: "20px",
                        fontWeight: 600,
                        margin: "24px 0 64px 0",
                    }}>{error}</Typography>

                    <Button variant="text" sx={{ width: 320 }} onClick={() => onClose && onClose()}>Close</Button>
                </Box>
            </>
        );
    }

    if (step === "saved") {
        return (
            <>
                <Box sx={{
                    border: "1px solid #EAEAEA",
                    borderRadius: "8px",
                    padding: "24px",
                    textAlign: "center",
                }}>
                    <Box sx={{
                        borderRadius: "80px",
                        background: "#50E3C2",
                        display: "inline-block",
                        width: "80px",
                        height: "80px",
                        padding: "20px",
                    }}>
                        <CheckIcon color="#FFFFFF" height={40} />
                    </Box>

                    <Typography sx={{
                        fontSize: "20px",
                        fontWeight: 600,
                        margin: "24px 0 64px 0",
                    }}>Your display rule has been saved</Typography>

                    <Button variant="text" sx={{ width: 320 }} onClick={() => onClose && onClose()}>Close</Button>
                </Box>
            </>

        );
    }

    return (
        <>
            <Step
                title="Condition"
                subtitle="Set up a condition"
                description="Choose a product attribute and value for which you want to create an video display rules for"
                visible={true}
                expanded={step === "condition"}
                onEdit={() => setStep("condition")}>
                <Select
                    fullWidth={true}
                    value={identifier}
                    onChange={(e) => {
                        setCondition(undefined);
                        setIdentifier(e.target.value as any);
                        setResult(undefined);
                    }}>
                    {Object.entries(identifiers).map(([ id, { name } ]) => (
                        <MenuItem key={id} value={id}>{name}</MenuItem>
                    ))}
                </Select>

                { condition === undefined && (
                    <Button
                        variant="text" fullWidth={true} onClick={() => {
                            if (identifier === "vehicle.manufacturer" || identifier === "vehicle.price.currency") {
                                setCondition(new OptionCondition(identifier, ""));
                            } else if (identifiers[identifier]?.type === "string") {
                                setCondition(new StringCondition(identifier, StringOp.Equals, ""));
                            } else if (identifiers[identifier]?.type === "float") {
                                setCondition(new FloatCondition(identifier, FloatOp.GreaterThan, 0));
                            } else {
                                setCondition(undefined);
                            }
                        }
                        }>Continue</Button>
                )}

                <Editor
                    condition={condition}
                    productionId={parseInt(pid!)}
                    onChange={condition => {
                        setCondition(condition);
                        setResult(undefined);
                    }} />

                { condition && (result ?? false) === false && (
                    <Button
                        variant="text" fullWidth={true} disabled={loading} onClick={async () => {
                            setLoading(true);
                            try {
                                setResult(await countRules(parseInt(pid!), condition?.toPql()));
                            } catch {
                                setResult(false);
                            } finally {
                                setLoading(false);
                            }
                        }}>{loading ? <Spinner /> : "Test Display rule"}</Button>
                )}

                { result === false && (
                    <Box>Err</Box>
                )}

                { condition !== undefined && typeof(result) === "object" && (
                    <>
                        <Box sx={{
                            textAlign: "center",
                            border: "1px solid #EAEAEA",
                            padding: "24px",
                            borderRadius: "8px",
                            marginTop: "16px",
                            flexGrow: 1,
                        }}>
                            <Typography sx={{
                                fontSize: "20px",
                                fontWeight: "bold"
                            }}>Test was sucessfull!</Typography>

                            <Box sx={{
                                display: "flex",
                                justifyContent: "center",
                                margin: "16px 0",
                            }}>
                                {condition.renderLogic()}
                            </Box>

                            <Typography sx={{
                                fontSize: "12px",
                                fontWeight: 500,
                                color: "#888888",
                            }}>Number of items</Typography>
                            {result.matches} / {result.total}
                        </Box>

                        <Button
                            variant="text" 
                            fullWidth={true}
                            onClick={() => setStep(ruleId === undefined ? "design" : "name")}>Continue</Button>
                    </>
                )}
            </Step>

            <Step
                title="Design"
                subtitle="Choose a design"
                description="Select a design for your display rule"
                visible={step === "design" || design !== undefined}
                expanded={step === "design"}>

                <Select
                    fullWidth={true} inputProps={{
                        inputRef: designRef
                    }}>
                    {designs.map(design => (
                        <MenuItem key={design.id} value={design.id}>{design.name}</MenuItem>
                    ))}
                </Select>
                <Button
                    variant="text" fullWidth={true} disabled={loading} onClick={async () => {
                        setLoading(true);
                        try {
                            const value = designRef.current?.value;
                            setDesign(designs.find(design => design.id === value));
                            setStep("name");
                        } finally {
                            setLoading(false);
                        }
                    }}>{loading ? <Spinner /> : "Continues"}</Button>
            </Step>

            <Step
                title="Rule name"
                subtitle="Choose a name for your display rule"
                visible={step === "name" || ruleId !== undefined}
                expanded={step === "name"}>
                <TextField fullWidth={true} value={name} onChange={(e) => setName(e.target.value as any)} />
                <Button
                    variant="text" fullWidth={true} disabled={loading} onClick={async () => {
                        setLoading(true);
                        try {
                            if (ruleId !== undefined) {
                                await saveRule({
                                    id: ruleId,
                                    name,
                                    expression: condition!.toPql()
                                });
                            } else {
                                await createRule(parseInt(pid!), design!.id, name, condition!.toPql());
                            }

                            setStep("saved");
                        } finally {
                            setLoading(false);
                        }
                    }}>{loading ? <Spinner /> : "Save rule"}</Button>
            </Step>
        </>
    )
};

export default Component;
