import { Customer, User } from "../common/types";
import { createContext } from "react";

type AuthContextState = {
    user?: User;
    customer?: Customer;
    setUserAndCustomer: (user?: User, customer?: Customer) => void;
    login: (username: string, password: string) => Promise<void>;
    logout: () => Promise<void>;
};

const AuthContext = createContext<AuthContextState>({} as any);

export default AuthContext;
