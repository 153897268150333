// Generated from Prl.g4 by ANTLR 4.9.3
// jshint ignore: start
import antlr4 from 'antlr4';



const serializedATN = ["\u0003\u608b\ua72a\u8133\ub9ed\u417c\u3be7\u7786",
    "\u5964\u0002\u0013\u0094\b\u0001\u0004\u0002\t\u0002\u0004\u0003\t\u0003",
    "\u0004\u0004\t\u0004\u0004\u0005\t\u0005\u0004\u0006\t\u0006\u0004\u0007",
    "\t\u0007\u0004\b\t\b\u0004\t\t\t\u0004\n\t\n\u0004\u000b\t\u000b\u0004",
    "\f\t\f\u0004\r\t\r\u0004\u000e\t\u000e\u0004\u000f\t\u000f\u0004\u0010",
    "\t\u0010\u0004\u0011\t\u0011\u0004\u0012\t\u0012\u0004\u0013\t\u0013",
    "\u0003\u0002\u0003\u0002\u0003\u0002\u0003\u0003\u0003\u0003\u0003\u0003",
    "\u0003\u0003\u0003\u0004\u0003\u0004\u0003\u0004\u0003\u0005\u0003\u0005",
    "\u0003\u0005\u0003\u0005\u0003\u0006\u0003\u0006\u0003\u0006\u0003\u0006",
    "\u0003\u0006\u0003\u0006\u0003\u0006\u0003\u0006\u0003\u0007\u0003\u0007",
    "\u0003\u0007\u0003\b\u0003\b\u0003\b\u0003\b\u0003\b\u0003\b\u0003\b",
    "\u0003\b\u0003\b\u0003\t\u0003\t\u0003\t\u0003\t\u0003\t\u0003\t\u0003",
    "\t\u0003\t\u0003\t\u0003\t\u0003\t\u0003\t\u0003\n\u0003\n\u0003\u000b",
    "\u0003\u000b\u0003\f\u0003\f\u0003\r\u0003\r\u0007\r^\n\r\f\r\u000e",
    "\ra\u000b\r\u0003\u000e\u0003\u000e\u0003\u000f\u0005\u000ff\n\u000f",
    "\u0003\u000f\u0006\u000fi\n\u000f\r\u000f\u000e\u000fj\u0003\u0010\u0005",
    "\u0010n\n\u0010\u0003\u0010\u0006\u0010q\n\u0010\r\u0010\u000e\u0010",
    "r\u0003\u0010\u0003\u0010\u0007\u0010w\n\u0010\f\u0010\u000e\u0010z",
    "\u000b\u0010\u0003\u0010\u0003\u0010\u0006\u0010~\n\u0010\r\u0010\u000e",
    "\u0010\u007f\u0005\u0010\u0082\n\u0010\u0003\u0011\u0003\u0011\u0003",
    "\u0011\u0003\u0011\u0007\u0011\u0088\n\u0011\f\u0011\u000e\u0011\u008b",
    "\u000b\u0011\u0003\u0011\u0003\u0011\u0003\u0012\u0003\u0012\u0003\u0012",
    "\u0003\u0012\u0003\u0013\u0003\u0013\u0002\u0002\u0014\u0003\u0003\u0005",
    "\u0004\u0007\u0005\t\u0006\u000b\u0007\r\b\u000f\t\u0011\n\u0013\u000b",
    "\u0015\f\u0017\r\u0019\u000e\u001b\u0002\u001d\u000f\u001f\u0010!\u0011",
    "#\u0012%\u0013\u0003\u0002\u0007\u0005\u0002C\\aac|\u0007\u0002002;",
    "C\\aac|\u0006\u0002\f\f\u000f\u000f$$^^\u0004\u0002$$^^\u0005\u0002",
    "\u000b\f\u000f\u000f\"\"\u0002\u009c\u0002\u0003\u0003\u0002\u0002\u0002",
    "\u0002\u0005\u0003\u0002\u0002\u0002\u0002\u0007\u0003\u0002\u0002\u0002",
    "\u0002\t\u0003\u0002\u0002\u0002\u0002\u000b\u0003\u0002\u0002\u0002",
    "\u0002\r\u0003\u0002\u0002\u0002\u0002\u000f\u0003\u0002\u0002\u0002",
    "\u0002\u0011\u0003\u0002\u0002\u0002\u0002\u0013\u0003\u0002\u0002\u0002",
    "\u0002\u0015\u0003\u0002\u0002\u0002\u0002\u0017\u0003\u0002\u0002\u0002",
    "\u0002\u0019\u0003\u0002\u0002\u0002\u0002\u001d\u0003\u0002\u0002\u0002",
    "\u0002\u001f\u0003\u0002\u0002\u0002\u0002!\u0003\u0002\u0002\u0002",
    "\u0002#\u0003\u0002\u0002\u0002\u0002%\u0003\u0002\u0002\u0002\u0003",
    "\'\u0003\u0002\u0002\u0002\u0005*\u0003\u0002\u0002\u0002\u0007.\u0003",
    "\u0002\u0002\u0002\t1\u0003\u0002\u0002\u0002\u000b5\u0003\u0002\u0002",
    "\u0002\r=\u0003\u0002\u0002\u0002\u000f@\u0003\u0002\u0002\u0002\u0011",
    "I\u0003\u0002\u0002\u0002\u0013U\u0003\u0002\u0002\u0002\u0015W\u0003",
    "\u0002\u0002\u0002\u0017Y\u0003\u0002\u0002\u0002\u0019[\u0003\u0002",
    "\u0002\u0002\u001bb\u0003\u0002\u0002\u0002\u001de\u0003\u0002\u0002",
    "\u0002\u001f\u0081\u0003\u0002\u0002\u0002!\u0083\u0003\u0002\u0002",
    "\u0002#\u008e\u0003\u0002\u0002\u0002%\u0092\u0003\u0002\u0002\u0002",
    "\'(\u0007q\u0002\u0002()\u0007t\u0002\u0002)\u0004\u0003\u0002\u0002",
    "\u0002*+\u0007c\u0002\u0002+,\u0007p\u0002\u0002,-\u0007f\u0002\u0002",
    "-\u0006\u0003\u0002\u0002\u0002./\u0007g\u0002\u0002/0\u0007s\u0002",
    "\u00020\b\u0003\u0002\u0002\u000212\u0007p\u0002\u000223\u0007q\u0002",
    "\u000234\u0007v\u0002\u00024\n\u0003\u0002\u0002\u000256\u0007d\u0002",
    "\u000267\u0007g\u0002\u000278\u0007v\u0002\u000289\u0007y\u0002\u0002",
    "9:\u0007g\u0002\u0002:;\u0007g\u0002\u0002;<\u0007p\u0002\u0002<\f\u0003",
    "\u0002\u0002\u0002=>\u0007k\u0002\u0002>?\u0007p\u0002\u0002?\u000e",
    "\u0003\u0002\u0002\u0002@A\u0007e\u0002\u0002AB\u0007q\u0002\u0002B",
    "C\u0007p\u0002\u0002CD\u0007v\u0002\u0002DE\u0007c\u0002\u0002EF\u0007",
    "k\u0002\u0002FG\u0007p\u0002\u0002GH\u0007u\u0002\u0002H\u0010\u0003",
    "\u0002\u0002\u0002IJ\u0007u\u0002\u0002JK\u0007v\u0002\u0002KL\u0007",
    "c\u0002\u0002LM\u0007t\u0002\u0002MN\u0007v\u0002\u0002NO\u0007u\u0002",
    "\u0002OP\u0007/\u0002\u0002PQ\u0007y\u0002\u0002QR\u0007k\u0002\u0002",
    "RS\u0007v\u0002\u0002ST\u0007j\u0002\u0002T\u0012\u0003\u0002\u0002",
    "\u0002UV\u0007*\u0002\u0002V\u0014\u0003\u0002\u0002\u0002WX\u0007+",
    "\u0002\u0002X\u0016\u0003\u0002\u0002\u0002YZ\u0007.\u0002\u0002Z\u0018",
    "\u0003\u0002\u0002\u0002[_\t\u0002\u0002\u0002\\^\t\u0003\u0002\u0002",
    "]\\\u0003\u0002\u0002\u0002^a\u0003\u0002\u0002\u0002_]\u0003\u0002",
    "\u0002\u0002_`\u0003\u0002\u0002\u0002`\u001a\u0003\u0002\u0002\u0002",
    "a_\u0003\u0002\u0002\u0002bc\u00042;\u0002c\u001c\u0003\u0002\u0002",
    "\u0002df\u0007/\u0002\u0002ed\u0003\u0002\u0002\u0002ef\u0003\u0002",
    "\u0002\u0002fh\u0003\u0002\u0002\u0002gi\u0005\u001b\u000e\u0002hg\u0003",
    "\u0002\u0002\u0002ij\u0003\u0002\u0002\u0002jh\u0003\u0002\u0002\u0002",
    "jk\u0003\u0002\u0002\u0002k\u001e\u0003\u0002\u0002\u0002ln\u0007/\u0002",
    "\u0002ml\u0003\u0002\u0002\u0002mn\u0003\u0002\u0002\u0002np\u0003\u0002",
    "\u0002\u0002oq\u0005\u001b\u000e\u0002po\u0003\u0002\u0002\u0002qr\u0003",
    "\u0002\u0002\u0002rp\u0003\u0002\u0002\u0002rs\u0003\u0002\u0002\u0002",
    "st\u0003\u0002\u0002\u0002tx\u00070\u0002\u0002uw\u0005\u001b\u000e",
    "\u0002vu\u0003\u0002\u0002\u0002wz\u0003\u0002\u0002\u0002xv\u0003\u0002",
    "\u0002\u0002xy\u0003\u0002\u0002\u0002y\u0082\u0003\u0002\u0002\u0002",
    "zx\u0003\u0002\u0002\u0002{}\u00070\u0002\u0002|~\u0005\u001b\u000e",
    "\u0002}|\u0003\u0002\u0002\u0002~\u007f\u0003\u0002\u0002\u0002\u007f",
    "}\u0003\u0002\u0002\u0002\u007f\u0080\u0003\u0002\u0002\u0002\u0080",
    "\u0082\u0003\u0002\u0002\u0002\u0081m\u0003\u0002\u0002\u0002\u0081",
    "{\u0003\u0002\u0002\u0002\u0082 \u0003\u0002\u0002\u0002\u0083\u0089",
    "\u0007$\u0002\u0002\u0084\u0088\n\u0004\u0002\u0002\u0085\u0086\u0007",
    "^\u0002\u0002\u0086\u0088\t\u0005\u0002\u0002\u0087\u0084\u0003\u0002",
    "\u0002\u0002\u0087\u0085\u0003\u0002\u0002\u0002\u0088\u008b\u0003\u0002",
    "\u0002\u0002\u0089\u0087\u0003\u0002\u0002\u0002\u0089\u008a\u0003\u0002",
    "\u0002\u0002\u008a\u008c\u0003\u0002\u0002\u0002\u008b\u0089\u0003\u0002",
    "\u0002\u0002\u008c\u008d\u0007$\u0002\u0002\u008d\"\u0003\u0002\u0002",
    "\u0002\u008e\u008f\t\u0006\u0002\u0002\u008f\u0090\u0003\u0002\u0002",
    "\u0002\u0090\u0091\b\u0012\u0002\u0002\u0091$\u0003\u0002\u0002\u0002",
    "\u0092\u0093\u000b\u0002\u0002\u0002\u0093&\u0003\u0002\u0002\u0002",
    "\r\u0002_ejmrx\u007f\u0081\u0087\u0089\u0003\b\u0002\u0002"].join("");


const atn = new antlr4.atn.ATNDeserializer().deserialize(serializedATN);

const decisionsToDFA = atn.decisionToState.map( (ds, index) => new antlr4.dfa.DFA(ds, index) );

export default class PrlLexer extends antlr4.Lexer {

    static grammarFileName = "Prl.g4";
    static channelNames = [ "DEFAULT_TOKEN_CHANNEL", "HIDDEN" ];
	static modeNames = [ "DEFAULT_MODE" ];
	static literalNames = [ null, "'or'", "'and'", "'eq'", "'not'", "'between'", 
                         "'in'", "'contains'", "'starts-with'", "'('", "')'", 
                         "','" ];
	static symbolicNames = [ null, "OR", "AND", "EQ", "NOT", "BETWEEN", "IN", 
                          "CONTAINS", "STARTS", "OPAR", "CPAR", "COMMA", 
                          "IDENTIFIER", "INT", "FLOAT", "STRING", "SPACE", 
                          "OTHER" ];
	static ruleNames = [ "OR", "AND", "EQ", "NOT", "BETWEEN", "IN", "CONTAINS", 
                      "STARTS", "OPAR", "CPAR", "COMMA", "IDENTIFIER", "DIGIT", 
                      "INT", "FLOAT", "STRING", "SPACE", "OTHER" ];

    constructor(input) {
        super(input)
        this._interp = new antlr4.atn.LexerATNSimulator(this, atn, decisionsToDFA, new antlr4.PredictionContextCache());
    }

    get atn() {
        return atn;
    }
}

PrlLexer.EOF = antlr4.Token.EOF;
PrlLexer.OR = 1;
PrlLexer.AND = 2;
PrlLexer.EQ = 3;
PrlLexer.NOT = 4;
PrlLexer.BETWEEN = 5;
PrlLexer.IN = 6;
PrlLexer.CONTAINS = 7;
PrlLexer.STARTS = 8;
PrlLexer.OPAR = 9;
PrlLexer.CPAR = 10;
PrlLexer.COMMA = 11;
PrlLexer.IDENTIFIER = 12;
PrlLexer.INT = 13;
PrlLexer.FLOAT = 14;
PrlLexer.STRING = 15;
PrlLexer.SPACE = 16;
PrlLexer.OTHER = 17;



