import { styled, Breadcrumbs } from "@mui/material";
import * as React from "react";

const Component = styled(Breadcrumbs)(_ => ({
    "& .MuiTypography-root.MuiLink-root": {
        fontSize: "14px",
        color: "#888888",
    },
    "& .MuiTypography-root": {
        fontSize: "14px",
        color: "#000000",
    },
}));

export default Component;
