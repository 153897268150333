// Generated from Prl.g4 by ANTLR 4.9.3
// jshint ignore: start
import antlr4 from 'antlr4';

// This class defines a complete generic visitor for a parse tree produced by PrlParser.

export default class PrlVisitor extends antlr4.tree.ParseTreeVisitor {

	// Visit a parse tree produced by PrlParser#query.
	visitQuery(ctx) {
	  return this.visitChildren(ctx);
	}


	// Visit a parse tree produced by PrlParser#ParenthesizedExpression.
	visitParenthesizedExpression(ctx) {
	  return this.visitChildren(ctx);
	}


	// Visit a parse tree produced by PrlParser#ContainsExpression.
	visitContainsExpression(ctx) {
	  return this.visitChildren(ctx);
	}


	// Visit a parse tree produced by PrlParser#AndExpression.
	visitAndExpression(ctx) {
	  return this.visitChildren(ctx);
	}


	// Visit a parse tree produced by PrlParser#BetweenExpression.
	visitBetweenExpression(ctx) {
	  return this.visitChildren(ctx);
	}


	// Visit a parse tree produced by PrlParser#InExpression.
	visitInExpression(ctx) {
	  return this.visitChildren(ctx);
	}


	// Visit a parse tree produced by PrlParser#StringExpression.
	visitStringExpression(ctx) {
	  return this.visitChildren(ctx);
	}


	// Visit a parse tree produced by PrlParser#LiteralExpression.
	visitLiteralExpression(ctx) {
	  return this.visitChildren(ctx);
	}


	// Visit a parse tree produced by PrlParser#NotExpression.
	visitNotExpression(ctx) {
	  return this.visitChildren(ctx);
	}


	// Visit a parse tree produced by PrlParser#EqualityExpression.
	visitEqualityExpression(ctx) {
	  return this.visitChildren(ctx);
	}


	// Visit a parse tree produced by PrlParser#StartsWithExpression.
	visitStartsWithExpression(ctx) {
	  return this.visitChildren(ctx);
	}


	// Visit a parse tree produced by PrlParser#OrExpression.
	visitOrExpression(ctx) {
	  return this.visitChildren(ctx);
	}


	// Visit a parse tree produced by PrlParser#IdentifierExpression.
	visitIdentifierExpression(ctx) {
	  return this.visitChildren(ctx);
	}


	// Visit a parse tree produced by PrlParser#literal.
	visitLiteral(ctx) {
	  return this.visitChildren(ctx);
	}



}