import { ReactElement, useContext, useEffect, useRef, useState } from "react";
import { Link as RouterLink, useParams } from "react-router-dom";
import { Design, Production, TemplateParameter } from "../../../../common/types";
import { getDesign, getProduction, saveDesign, uploadFile } from "../../../../common/api";
import { SxProps } from "@mui/system";
import { MinusIcon, PlusIcon } from "@heroicons/react/solid";
import {
    ColorPicker, Heading, Breadcrumbs,
    Box, Button,
    CircularProgress, Fab, FormHelperText, Link,
    MenuItem, OutlinedInput,
    Select,
    Switch,
    Tab,
    Tabs,
    Theme, Typography
} from "../../../../components";
import * as React from "react";
import CampaignContext from "../../../../contexts/CampaignContext";

type Props = {
    design: Design;
    parameter: TemplateParameter;
    onChange: () => void;
    sx?: SxProps<Theme>
};

/* eslint-disable @typescript-eslint/no-use-before-define */

const Parameter = ({ parameter, ...rest }: Props): ReactElement => {
    const { type } = parameter;

    switch (type) {
        case "group":
            return <Group parameter={parameter} {...rest} />;
        case "color":
            return <Color parameter={parameter} {...rest} />;
        case "enum":
            return <Enum parameter={parameter} {...rest} />;
        case "boolean":
            return <Boolean parameter={parameter} {...rest} />;
        case "string":
            return <String parameter={parameter} {...rest} />;
        case "list":
            return <List parameter={parameter} {...rest} />;
        case "integer":
            return <Integer parameter={parameter} {...rest} />;
        case "file":
            return <File parameter={parameter} {...rest} />
        default:
            return <div>Not implemented {parameter.type}</div>;
    }
};

/* eslint-enable @typescript-eslint/no-use-before-define */

const Reset = ({ parameter, onChange }: Omit<Props, "design">): ReactElement => {
    return parameter.default && (
        <Typography
            sx={{
                float: "right",
                cursor: "pointer",
                color: "blue",
                fontSize: "12px",
            }}
            onClick={() => {
                parameter.value = parameter.default;
                onChange();
            }}>
          Reset
        </Typography>
    );
}

const Color = ({ sx, parameter, onChange }: Props): ReactElement => {
    const { name, value, default: def, error } = parameter;

    return (
        <Box sx={sx}>
            <Typography
                variant="h3"
                sx={{
                    flexBasis: "100%",
                    fontSize: "14px",
                    fontWeight: 500,
                }}>
                {name}
                <Reset parameter={parameter} onChange={onChange} />
            </Typography>
            <ColorPicker
                color={value ?? def}
                onChange={(c) => {
                    parameter.value = c;
                    onChange();
                }}
            />
            {error && (
                <FormHelperText error>{error}</FormHelperText>
            )}
        </Box>
    );
};

const Group = ({ sx, parameter, onChange, design }: Props): ReactElement => {
    const { style = "section", name, children = [], description } = parameter
    const [ tab, setTab ] = useState(children[0].key);
    const enableChild = children.find(child => child.key === "enable");

    return (
        <Box sx={{
            flexBasis: "100% !important",
            border: "1px solid #EAEAEA",
            borderRadius: "4px",
            backgroundColor: "#FFFFFF",
            padding: style === "tabs" ? "40px" : "24px",

            display: "flex",
            flexDirection: "row",
            flexWrap: "wrap",
            alignItems: "flex-start",
            alignContent: "stretch",

            "& > *": {
                flex: "1 0 400px",
                margin: "0 24px 24px 0",
            },

            ...sx
        }}>
            {style === "tabs" && (
                <Tabs
                    sx={{
                        marginBottom: "24px",
                        flexBasis: "100%"
                    }}
                    value={tab} onChange={(_, value) => setTab(value)}>
                    {children
                        .filter(child => child.type === "group" && child.style === "tab")
                        .map((child) => (
                            <Tab
                                key={child.key} label={child.name} value={child.key}
                                sx={{
                                    fontSize: "14px"
                                }}
                            />
                        ))}
                </Tabs>
            )}

            {style !== "tabs" && (
                <>
                    <Typography
                        variant="h2"
                        sx={{
                            flexBasis: "100% !important",
                            fontSize: "20px",
                            fontWeight: 600,
                        }}>
                        {name}
                    </Typography>
                    <Typography
                        sx={{
                            flexBasis: "100% !important",
                            margin: "8px 0",
                        }}>
                        {description}xx
                    </Typography>
                </>

            )}

            {enableChild && (
                <Parameter
                    sx={{
                        display: style === "tabs" && enableChild.key !== tab
                            ? "none"
                            : enableChild.type === "group" ? "flex" : "unset"
                    }}
                    key={enableChild.key}
                    design={design}
                    parameter={enableChild}
                    onChange={onChange} />
            )}

            {(enableChild === undefined || enableChild.value) &&
             children
                 .filter(child => child !== enableChild)
                 .map((child, i) => (
                     <Parameter
                         sx={{
                             display: style === "tabs" && child.key !== tab
                                 ? "none"
                                 : child.type === "group" ? "flex" : "unset"
                         }}
                         key={i}
                         design={design}
                         parameter={child}
                         onChange={onChange} />
                 ))}
        </Box>
    );
};

const Enum = ({ sx, parameter, onChange }: Props): ReactElement => {
    const { name, value, default: def, error } = parameter;

    if (value === undefined) {
        parameter.value = def;
    }

    return (
        <Box sx={sx}>
            <Typography
                variant="h3"
                sx={{
                    fontSize: "14px",
                    fontWeight: 500,
                }}>
                {name}
                <Reset parameter={parameter} onChange={onChange} />
            </Typography>
            <Select
                value={value ?? def ?? undefined}
                onChange={(e) => {
                    parameter.value = e.target.value;
                    onChange();
                }}
                sx={{
                    width: "100%"
                }}
            >
                {parameter.children!.map((child) => (
                    <MenuItem key={child.key} value={child.key}>
                        {child.name}
                    </MenuItem>
                ))}
            </Select>
            {error && (
                <FormHelperText error>{error}</FormHelperText>
            )}
        </Box>
    );
};

const Boolean = ({ sx, parameter, onChange }: Props): ReactElement => {
    const { name, value, key, default: def, error } = parameter;

    if (value === undefined) {
        parameter.value = false;
    }

    return (
        <Box sx={sx}>
            { key !== "enable" &&
              <Typography
                  variant="h3"
                  sx={{
                      fontSize: "14px",
                      fontWeight: 500,
                  }}>
                  {name}
              </Typography>
            }
            <Switch
                checked={value ?? def ?? undefined}
                onChange={(e) => {
                    parameter.value = e.target.checked;
                    onChange();
                }}
            />
            <Typography sx={{
                fontSize: "14px",
                display: "inline-block",
                color: "#666",
            }}>
              Enabled
            </Typography>
            {error && (
                <FormHelperText error>{error}</FormHelperText>
            )}
        </Box>
    );
};

const String = ({ sx, parameter, onChange }: Props): ReactElement => {
    const { name, value, default: def, error } = parameter;

    return (
        <Box sx={sx}>
            <Typography
                variant="h3"
                sx={{
                    fontSize: "14px",
                    fontWeight: 500,
                }}>
                {name}
                <Reset parameter={parameter} onChange={onChange} />
            </Typography>
            <OutlinedInput
                fullWidth
                value={value ?? def ?? ""}
                onChange={(e) => {
                    parameter.value = e.target.value;
                    onChange();
                }}
            />
            {error && (
                <FormHelperText error>{error}</FormHelperText>
            )}
        </Box>
    );
};

const File = ({ sx, parameter, onChange, design }: Props): ReactElement => {
    const { name, value, default: def, error } = parameter;
    const ref = useRef<HTMLInputElement>(null);
    const url = parameter.value?.public_url ?? parameter.value?.url;
    const [ imageUrl, setImageUrl ] = useState<String | undefined>();

    useEffect(() => {
        if (url !== undefined) {
            (async () => {
                for (let i = 0; i < 10; i++) {
                    try {
                        const response = await fetch(url);

                        if (response.ok) {
                            setImageUrl(URL.createObjectURL(await response.blob()));
                            break;
                        }
                    }
                    catch {
                        // Whatever.
                    }

                    await new Promise((resolve) => setTimeout(resolve, 5000));
                }
            })();
        }
    }, [ url ]);

    return (
        <Box sx={sx}>
            <Typography
                variant="h3"
                sx={{
                    fontSize: "14px",
                    fontWeight: 500,
                }}>
                {name}
            </Typography>
            { imageUrl !== undefined ? (
                <Box
                    component="img"
                    src={imageUrl.toString()}
                    sx={{
                        maxWidth: "400px",
                        maxHeight: "100px",
                        display: "block",
                        marginBottom: "8px",
                    }}
                />
            ) : (
                <CircularProgress sx={{
                    display: "block",
                    margin: "16px 0"
                }} />
            )}
            <input
                ref={ref}
                type="file"
                hidden
                onChange={async e => {
                    if (e.target.files?.length === 1) {
                        const file = e.target.files[0];

                        if (file.size >= 1048576) {
                            alert("Too big- lol");
                            return;
                        }

                        setImageUrl(undefined);
                        parameter.value = await uploadFile(design.campaign_id!, file);
                        onChange();
                    }
                }} />
            <Button
                variant="contained"
                onClick={() => ref.current?.click()}
            >
            Upload
            </Button>
            {error && (
                <FormHelperText error>{error}</FormHelperText>
            )}
        </Box>
    );
};

const Integer = ({ sx, parameter, onChange }: Props): ReactElement => {
    const { name, value, default: def, error } = parameter;

    return (
        <Box sx={sx}>
            <Typography
                variant="h3"
                sx={{
                    fontSize: "14px",
                    fontWeight: 500,
                }}>
                {name}
                <Reset parameter={parameter} onChange={onChange} />
            </Typography>
            <OutlinedInput
                fullWidth
                value={value ?? def ?? ""}
                onChange={(e) => {
                    parameter.value = /^\s*$/.test(e.target.value) ? null : parseInt(e.target.value);
                    onChange();
                }}
            />
            {error && (
                <FormHelperText error>{error}</FormHelperText>
            )}
        </Box>
    );
};

const List = ({ sx, parameter, onChange, design }: Props): ReactElement => {
    if (parameter.value === undefined) {
        parameter.value = [];
    }

    const { name, value, definition, default: def, error } = parameter;
    const jsonDefinition = JSON.stringify(definition);

    return (
        <Box sx={{
            position: "relative",
            flexBasis: "100%",
            ...sx
        }}>
            <Fab
                color="primary"
                aria-label="add"
                sx={{
                    position: "absolute",
                    right: "0px",
                    top: "0px",
                    width: "40px",
                    height: "40px",
                    minHeight: "30px",
                }}
                onClick={() => {
                    value.push(JSON.parse(jsonDefinition));
                    onChange();
                }}
            >
                <PlusIcon />
            </Fab>

            <Typography
                variant="h3"
                sx={{
                    fontSize: "14px",
                    fontWeight: 500,
                }}>
                {name}
            </Typography>

            <Box
                sx={{
                    border: "1px solid #EAEAEA",
                    borderRadius: "4px",
                    backgroundColor: "#FFFFFF",
                    padding: "24px",

                    display: "flex",
                    flexDirection: "row",
                    alignContent: "stretch",
                    flexWrap: "wrap",

                    "& > *": {
                        margin: "0 24px 24px 0",
                        flex: "1 0 400px",
                    },
                }}
            >
                {value.map((entry: any, index: number) => (
                    <Box
                        key={index}
                        sx={{
                            position: "relative"
                        }}
                    >
                        <Fab
                            color="primary"
                            aria-label="add"
                            sx={{
                                position: "absolute",
                                right: "0px",
                                top: "0px",
                                width: "30px",
                                height: "30px",
                                minHeight: "30px",
                            }}
                            onClick={() => {
                                value.splice(index, 1);
                                onChange();
                            }}
                        >
                            <MinusIcon />
                        </Fab>
                        <Parameter
                            parameter={entry}
                            onChange={onChange}
                            design={design}
                        />
                    </Box>
                ))}
            </Box>
        </Box>);
};

const Page = () => {
    const { did } = useParams();
    const { campaign } = useContext(CampaignContext);
    const [ design, setDesign ] = useState(undefined as Design | undefined);
    const [ designChanged, setDesignChanged ] = useState(false)
    const [ production, setProduction ] = useState<Production | undefined>();
    const { pid } = useParams();

    useEffect(() => {
        getProduction(parseInt(pid!)).then(setProduction);
        getDesign(parseInt(did!)).then(setDesign);
    }, [ did ]);

    if (design === undefined) {
        return <CircularProgress />;
    }

    return (
        <Box
            sx={{
                display: "flex",
                flexDirection: "row",
                flexWrap: "wrap",
                alignItems: "flex-start",
                alignContent: "stretch",
                "& > *": {
                    alignSelf: "stretch",
                    minWidth: "400px",
                    flexBasis: "100%",
                },
                "& h3, & h4": {
                    margin: "8px 0",
                },
            }}
        >
            <Breadcrumbs aria-label="breadcrumb">
                <Link underline="hover" component={RouterLink} to="/campaigns" >Campaigns</Link>
                <Link underline="hover" component={RouterLink} to={`/campaigns/${campaign?.id}/productions`}>Productions</Link>
                <Link underline="hover" component={RouterLink} to={`/campaigns/${campaign?.id}/productions/${production?.id}/designs`}>{production?.name}</Link>
                <Typography color="text.primary">{design?.name}</Typography>
            </Breadcrumbs>

            <Heading>{design.name}</Heading>

            <Parameter
                parameter={design.parameters!}
                onChange={() => {
                    setDesign({ ...design })
                    setDesignChanged(true)
                }}
                design={design}
            />

            <Box sx={{
                position: "fixed",
                bottom: "0px",
                left: "0px",
                right: "0px",
                background: "#FFFFFF",
                height: "72px",
                boxShadow: "0px -1px 0px #EAEAEA",
            }}>
                <Button
                    sx={{
                        position: "absolute",
                        right: "48px",
                        top: "50%",
                        transform: "translateY(-50%)"
                    }}
                    disabled={designChanged === false}
                    variant="contained"
                    onClick={() => {
                        saveDesign(design).then(design => {
                            setDesign(design)
                            setDesignChanged(false)
                        });
                    }}
                >
                    Publish
                </Button>
            </Box>
        </Box>
    );
};

export default Page;
