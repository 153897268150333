import { useEffect, useRef, useState } from "react";
import { getCampaigns } from "../common/api";
import { Campaign } from "../common/types";
import {
    Badge, Table, Button, Tab,
    Box,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    Link, TextField, Tabs, IconButton,
    Dot,
} from "../components";
import { ChevronRightIcon, SearchIcon } from "@heroicons/react/solid";
import * as React from "react";
import { Pagination } from "./index";

type Props = {
    onSelect: (campaign: Campaign) => void;
};

type FilterFunc = (campaign: Campaign) => boolean;

const filters: FilterFunc[] = [
    _ => true,
    campaign => campaign.end_date == null || Date.parse(campaign.end_date) > Date.now(),
    campaign => campaign.end_date != null && Date.parse(campaign.end_date) < Date.now(),
]

const Component = ({ onSelect }: Props) => {
    const [ campaigns, setCampaigns ] = useState<Campaign[]>([]);
    const [ filter, setFilter ] = useState(0);
    const [ filterStr, setFilterStr ] = useState("");
    const [ searchString, setSearchString ] = useState("");
    const [ page, setPage ] = useState(1);
    const filteredCampaigns = campaigns.filter(campaign => filters[filter](campaign) && (filterStr.trim() === "" || campaign.name.toLowerCase().indexOf(filterStr) != -1));
    const campaignsPerPage = 10;

    useEffect(() => {
        getCampaigns().then(setCampaigns);
    }, []);

    const countCampaigns = (filter: FilterFunc): number =>
        campaigns
            .filter(campaign => filter(campaign) && (filterStr.trim() === "" || campaign.name.toLowerCase().indexOf(filterStr) != -1))
            .length;

    return (
        <Box
            sx={{
                borderRadius: "4px",
                background: "white",
                minHeight: "200px",
                padding: "24px",
                minWidth: "1200px",
            }}
        >
            <Box sx={{
                display: "flex",
                flexDirection: "row"
            }}>
                <TextField
                    InputLabelProps={{ shrink: false }}
                    InputProps={{ placeholder: "Search" }}
                    onChange={e => setSearchString(e.target.value as string)}
                    onKeyPress={(ev) => {
                        if (ev.key === "Enter") {
                            setFilterStr(searchString.toLowerCase());
                            ev.preventDefault();
                        }
                    }}
                />
                <Button
                    variant="contained"
                    sx={{
                        marginLeft: "8px"
                    }}
                    onClick={() => setFilterStr(searchString.toLowerCase())}
                >
                    <SearchIcon height={20} />&nbsp;Search
                </Button>
            </Box>

            <Tabs
                sx={{
                    marginTop: "10px"
                }} value={filter}>
                <Tab
                    label={
                        <Box>
                            All
                            <Badge active={filter === 0} count={countCampaigns(filters[0])} />
                        </Box>
                    }
                    onClick={() => setFilter(0)}
                />
                <Tab
                    label={
                        <Box>
                            Active
                            <Badge active={filter === 1} count={countCampaigns(filters[1])} />
                        </Box>
                    }
                    onClick={() => setFilter(1)}
                />
                <Tab
                    label={
                        <Box>
                            Inactive
                            <Badge active={filter === 2} count={countCampaigns(filters[2])} />
                        </Box>
                    }
                    onClick={() => setFilter(2)}
                />
            </Tabs>

            <Table
                sx={{
                    "thead > tr > th": {
                        fontWeight: 600,
                    },
                    "tbody > tr > td": {
                        fontWeight: 500,
                    },
                }}
            >
                <TableHead>
                    <TableRow>
                        <TableCell>
                            <Dot enabled={false} />
                        </TableCell>
                        <TableCell>Campaign</TableCell>
                        <TableCell>Group</TableCell>
                        <TableCell align="right" width={100}>Products</TableCell>
                        <TableCell align="right" width={100}>Views</TableCell>
                        <TableCell width={32}>&nbsp;</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody
                    sx={{
                        "& tr": {
                            cursor: "pointer",
                        }
                    }}
                >
                    {filteredCampaigns
                        .slice(page * campaignsPerPage - campaignsPerPage, page * campaignsPerPage)
                        .map((campaign) => (
                            <TableRow
                                key={campaign.id}
                                onClick={e => {
                                    e.preventDefault();
                                    onSelect(campaign);
                                    return false;
                                }}
                            >
                                <TableCell width={32}>
                                    <Dot enabled={filters[1](campaign)} />
                                </TableCell>
                                <TableCell>
                                    <Link
                                        sx={{
                                            cursor: "pointer"
                                        }}
                                        href={`/campaigns/${campaign.id}`}
                                        onClick={e => {
                                            e.preventDefault();
                                            onSelect(campaign);
                                            return false;
                                        }}
                                    >
                                        {campaign.name}
                                    </Link>
                                </TableCell>
                                <TableCell>
                                    {campaign.group}
                                </TableCell>
                                <TableCell align="right">
                                    {campaign.products}
                                </TableCell>
                                <TableCell align="right">
                                    {campaign.total_views}
                                </TableCell>
                                <TableCell>
                                    <IconButton
                                        sx={{
                                            background: "#FAFAFA",
                                        }}>
                                        <ChevronRightIcon color="black" height={20} />
                                    </IconButton>
                                </TableCell>
                            </TableRow>

                        ))}
                </TableBody>
            </Table>
            <Pagination
                sx={{ marginTop: "16px" }}
                page={page}
                count={Math.ceil((filteredCampaigns.length ?? 1) / campaignsPerPage)}
                onChange={(e, page) => setPage(page)}
            />
        </Box>
    );
};

export default Component;
