import React, { useEffect, useState } from "react";
import { Box, createTheme, CssBaseline, ThemeProvider } from "@mui/material";
import { Navigate, Route, Routes, Outlet } from "react-router-dom";
import DefaultLayout from "./layouts/DefaultLayout";
import { Campaign, Customer, User } from "./common/types";
import Login from "./pages/login";
import DesignsPage from "./pages/campaigns/productions/designs";
import CampaignsPage from "./pages/campaigns";
import DashboardPage from "./pages/campaigns/dashboard";
import EditDesignPage from "./pages/campaigns/productions/designs/edit";
import CreateDesignPage from "./pages/campaigns/productions/designs/create";
import RulesPage from "./pages/campaigns/productions/designs/rules";
import ProductsPage from "./pages/campaigns/products";
import ProductionsPage from "./pages/campaigns/productions";
import { getCurrentLogin, login, logout } from "./common/api";
import AuthContext from "./contexts/AuthContext";
import CampaignContext from "./contexts/CampaignContext";

const theme = createTheme({
    typography: {
        fontFamily: "'Inter', sans-serif",
    },
    palette: {
        background: {
            default: "#FAFAFA",
        },
    },
});

const AuthorizedContent = (): JSX.Element => (
    <Routes>
        <Route element={<DefaultLayout />}>
            <Route path="login" element={<Navigate to="/campaigns" />} />
            <Route index element={<Navigate to="campaigns" />} />
            <Route
                path="campaigns/:cid" element={
                    <Box sx={{ padding: "16px 24px 0 24px" }}>
                        <Outlet />
                    </Box>
                }>
                <Route path="productions/:pid/designs" element={<DesignsPage />} />
                <Route path="productions/:pid/designs/rules" element={<RulesPage />} />
                <Route path="productions" element={<ProductionsPage />} />
                <Route path="products" element={<ProductsPage />} />
                <Route path="productions/:pid/designs/create" element={<CreateDesignPage />} />
                <Route path="productions/:pid/designs/:did" element={<EditDesignPage />} />
                <Route path="dashboard" element={<DashboardPage />} />
                <Route index element={<Navigate to="dashboard" />} />
            </Route>
            <Route
                path="campaigns" element={
                    <Box sx={{ padding: "0 24px" }}>
                        <CampaignsPage />
                    </Box>
                } />
        </Route>
    </Routes>
);

const UnauthorizedContent = (): JSX.Element => (
    <Routes>
        <Route element={<DefaultLayout />}>
            <Route path="login" element={<Login />} />
            <Route path="*" element={<Navigate to="/login" />} />
        </Route>
    </Routes>
);

const App = () => {
    const [ initializing, setInitializing ] = useState(true);
    const [ user, setUser ] = useState<User | undefined>();
    const [ customer, setCustomer ] = useState<Customer | undefined>();
    const [ campaign, setCampaign ] = useState<Campaign | undefined>();

    useEffect(() => {
        getCurrentLogin().then(login => {
            if (login === undefined) {
                setUser(undefined);
                setCustomer(undefined);
            } else {
                const { user, customer } = login;
                setUser(user);
                setCustomer(customer);
            }
            setInitializing(false);
        });
    }, []);

    if (initializing) {
        return <div>Initializing...</div>;
    }

    return (
        <AuthContext.Provider
            value={{
                customer,
                user,
                setUserAndCustomer: (user, customer) => {
                    setUser(user);
                    setCustomer(customer);
                },
                login: async (username, password) => {
                    const { user, customer } = await login(username, password);
                    setUser(user);
                    setCustomer(customer);
                },
                logout: async () => {
                    await logout();
                    setUser(undefined);
                    setCustomer(undefined);
                },
            }}
        >
            <CampaignContext.Provider
                value={{
                    campaign,
                    activate: setCampaign,
                }}
            >
                <ThemeProvider theme={theme}>
                    <CssBaseline />
                    { customer ? <AuthorizedContent /> : <UnauthorizedContent />}
                </ThemeProvider>
            </CampaignContext.Provider>
        </AuthContext.Provider>
    );
};

export default App;
