import { Link as RouterLink } from "react-router-dom";
import * as React from "react";
import {
    CheckIcon, ChevronRightIcon,
    ClockIcon,
    CloudIcon,
    CollectionIcon,
    ColorSwatchIcon,
    FilmIcon,
    RefreshIcon, StarIcon
} from "@heroicons/react/solid";
import { useContext, useEffect, useState } from "react";
import CampaignContext from "../../contexts/CampaignContext";
import { getDashboardDetails } from "../../common/api";
import { DashboardDetails } from "../../common/types";
import {
    Heading, Table, Breadcrumbs,
    Box,
    IconButton,
    Link,
    Skeleton,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    Typography
} from "../../components";

type BlockProps = {
    title: string,
    icon?: React.ReactNode,
    status?: boolean,
    children?: React.ReactNode,
};

const Block = ({ title, icon, status, children }: BlockProps) => (
    <Box sx={{
        border: "1px solid #EAEAEA",
        borderRadius: "4px",
        background: "#FFFFFF",
        flex: "1 0",
        margin: "24px 0 0 24px",
        padding: "24px",
    }}>
        <Box sx={{
            width: "calc(100% - 80px)",
            marginBottom: "24px",
            fontSize: "16px",
            fontWeight: 600,
            display: "flex",
            flexDirection: "row",
            lineHeight: "32px",
        }}>
            <Box sx={{
                flex: "1 0 32px",
                width: "32px",
                height: "32px",
                padding: "8px",
                background: "#D3E5FF",
                borderRadius: "4px",
                marginRight: "12px",
                lineHeight: "16px",
                color: "#0070F3",
            }}>
                {icon}
            </Box>

            <Box sx={{
                flex: "1 0 100%",
            }}>
                {title}
            </Box>

            { status !== undefined && <Box sx={{
                flex: "1 0 16px",
                background: status ? "#77D692" : "#EE0000",
                borderRadius: "8px",
                height: "16px",
                marginLeft: "16px",
            }} />}
        </Box>
        {children}
    </Box>
);

type BlockRowProps = {
    children?: React.ReactNode,
};

const BlockRow = ({children}: BlockRowProps) => (
    <Box sx={{
        display: "flex",
        flexDirection: "row",
        flexWrap: "wrap",
        alignItems: "flex-start",
        alignContent: "stretch",
        margin: "-24px 0 24px -24px",
    }}>
        {children}
    </Box>
);

const Page = () => {
    const { campaign } = useContext(CampaignContext);
    const [ details, setDetails ] = useState<DashboardDetails | undefined>();
    const [ loading, setLoading ] = useState(true);

    useEffect(() => {
        if (campaign) {
            getDashboardDetails(campaign.id).then(d => {
                setDetails(d);
                setLoading(false);
            });
        }
    }, [ campaign ]);

    const parseDate = (date: string | null | undefined): Date | undefined =>
        date ? new Date(date) : undefined;

    const endDate = campaign?.end_date ? new Date(campaign.end_date) : null;
    const isActive = endDate == null || endDate > new Date();

    return (
        <Box>
            <Breadcrumbs aria-label="breadcrumb">
                <Link underline="hover" to="/campaigns" component={RouterLink} >
                    Campaigns
                </Link>
                <Typography>Dashboard</Typography>
            </Breadcrumbs>

            <Heading>Dashboard</Heading>

            <BlockRow>
                <Block title="Status" icon={<CheckIcon />} status={isActive}>
                    <Box sx={{
                        fontSize: "36px",
                        fontWeight: 600,
                    }}>
                        {
                            loading
                                ? "Loading..."
                                : (isActive ? "Active" : "Inactive")
                        }
                    </Box>
                </Block>
                <Block title="Inventory Update" icon={<CloudIcon />}>
                    <Box sx={{
                        fontSize: "36px",
                        fontWeight: 600,
                    }}>
                        {
                            loading
                                ? "Loading..."
                                : parseDate(details?.campaign.feed_synced_at)?.toLocaleDateString("sv-SE") ?? "N/A"
                        }
                    </Box>
                </Block>
                <Block title="Campaign Created" icon={<ClockIcon />}>
                    <Box sx={{
                        fontSize: "36px",
                        fontWeight: 600,
                    }}>
                        {
                            loading
                                ? "Loading..."
                                : parseDate(details?.campaign.created_at)?.toLocaleDateString("sv-SE") ?? "Loading..."
                        }
                    </Box>
                </Block>
            </BlockRow>
            <BlockRow>
                <Block title="Active Designs" icon={<ColorSwatchIcon />}>
                    <Box sx={{
                        fontSize: "36px",
                        fontWeight: 600,
                    }}>
                        {
                            loading
                                ? "Loading..."
                                : details?.total_active_designs
                        }
                    </Box>
                </Block>
                <Block title="Total Generated Videos" icon={<CollectionIcon />}>
                    <Box sx={{
                        fontSize: "36px",
                        fontWeight: 600,
                    }}>
                        {
                            loading
                                ? "Loading..."
                                : details?.total_videos_created
                        }
                    </Box>
                </Block>
                <Block title="Active Videos" icon={<RefreshIcon />}>
                    <Box sx={{
                        fontSize: "36px",
                        fontWeight: 600,
                    }}>
                        {
                            loading
                                ? "Loading..."
                                : details?.total_active_videos
                        }
                    </Box>
                </Block>
            </BlockRow>
            <BlockRow>
                <Block title="Latest Videos" icon={<FilmIcon />}>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell>Video</TableCell>
                                <TableCell>Product Key</TableCell>
                                <TableCell>Created</TableCell>
                                <TableCell />
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {details?.recently_completed_videos.map((video, index) => {
                                const createdAt = new Date(video.created_at);
                                const deltaDays = Math.round((Date.now() - createdAt.getTime()) / 86400000);
                                const createdAtStr = deltaDays > 365 || deltaDays < 0 ? createdAt.toLocaleDateString("sv-SE") : `${deltaDays} days ago`;

                                return (
                                    <TableRow key={index}>
                                        <TableCell style={{ width: "96px" }}>
                                            {video.thumbnail_url ?
                                                <img src={video.thumbnail_url} width={64} style={{ borderRadius: "4px" }} /> :
                                                <Skeleton width={64} height={64} sx={{ borderRadius: "4px" }} />
                                            }
                                        </TableCell>
                                        <TableCell>{video.product_key}</TableCell>
                                        <TableCell>{createdAtStr}</TableCell>
                                        <TableCell width={32}>
                                            <IconButton
                                                onClick={() => window.open(video.video_url, "_blank")}
                                                sx={{
                                                    background: "#FAFAFA",
                                                }}>
                                                <ChevronRightIcon color="black" height={20} />
                                            </IconButton>
                                        </TableCell>
                                    </TableRow>
                                )
                            })}
                        </TableBody>
                    </Table>
                </Block>
                <Block title="Top Videos This Month" icon={<StarIcon />}>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell>Video</TableCell>
                                <TableCell>Product Key</TableCell>
                                <TableCell>Views</TableCell>
                                <TableCell />
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {details?.top_performing_videos?.map((video, index) => {
                                return (
                                    <TableRow key={index}>
                                        <TableCell style={{ width: "96px" }}>
                                            {video.thumbnail_url ?
                                                <img src={video.thumbnail_url} width={64} style={{ borderRadius: "4px" }} /> :
                                                <Skeleton width={64} height={64} sx={{ borderRadius: "4px" }} />
                                            }
                                        </TableCell>
                                        <TableCell>{video.product_key}</TableCell>
                                        <TableCell>{video.view_starts}</TableCell>
                                        <TableCell width={32}>
                                            <IconButton
                                                onClick={() => window.open(video.video_url, "_blank")}
                                                sx={{
                                                    background: "#FAFAFA",
                                                }}>
                                                <ChevronRightIcon color="black" height={20} />
                                            </IconButton>
                                        </TableCell>
                                    </TableRow>
                                )
                            })}
                        </TableBody>
                    </Table>
                </Block>
            </BlockRow>
        </Box>
    );
};

export default Page;
