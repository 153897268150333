import { Box } from "@mui/material";

type Props = {
    count: number;
    active: boolean;
};

const Component = ({ count, active }: Props) => (
    <Box sx={{
        display: "inline-block",
        background: active ? "#0070F3" : "#EAEAEA",
        borderRadius: "4px",
        padding: "4px",
        color: active ? "white" : "black",
        marginLeft: "8px",
        fontSize: "12px",
        lineHeight: "100%",
    }}>{count}</Box>
)

export default Component;

