import { Box, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useContext } from "react";
import AuthContext from "../../contexts/AuthContext";
import { CampaignList } from "../../components";

const Page = () => {
    const navigate = useNavigate();
    const { customer } = useContext(AuthContext);

    return (
        <Box>
            <Typography
                variant="h1"
                sx={{
                    fontSize: "32px",
                    fontWeight: 700,
                    margin: "0.8em 0 10px 0",
                    color: "#000000",
                }}
            >
                Welcome, {customer?.name}
            </Typography>

            <Typography
                sx={{
                    fontSize: "16px",
                    lineHeight: "24px",
                    fontWeight: 400,
                    marginBottom: "40px",
                }}
            >
                Proin sodales nulla tincidunt, eleifend tortor et, accumsan just
            </Typography>

            <Box sx={{
                border: "1px solid #EAEAEA",
                borderRadius: "4px",
            }}>
                <CampaignList onSelect={campaign => navigate(`/campaigns/${campaign.id}`)} />
            </Box>
        </Box>
    );
};

export default Page;
