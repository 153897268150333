import { styled, Button } from "@mui/material";
import * as React from "react";

const Component = styled(Button)(_ => ({
    backgroundColor: "#0070F3",
    boxShadow: "initial",
    color: "white",
    height: "48px",
    fontSize: "14px",
    fontWeight: 500,
    textTransform: "none",
    padding: "0 24px",
    "&:hover": {
        boxShadow: "initial",
        backgroundColor: "#0070F3",
    }
}));

export default Component;
