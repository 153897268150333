import { styled, TextField } from "@mui/material";
import * as React from "react";

const Component = styled(TextField)(_ => ({
    "& .MuiInputBase-input": {
        padding: "14px 12px 14px 12px",
        fontSize: "14px",
        minWidth: "480px",
    },
}));

Component.defaultProps = {

}

export default Component;
