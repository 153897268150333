const Logo = (props: any) => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 246.69 41.43"
            {...props}
        >
            <g id="Lager_2">
                <g id="Lager_1-2">
                    <path
                        className="cls-1"
                        d="M37.83,8.43H0v33H8.05V33H32.79l5.05-5Zm-8,16.79H8.05v-9H29.79Z"
                    />
                    <path
                        className="cls-1"
                        d="M192.57,8.43H154.75V33h32.78l5.05-5.05Zm-8,16.78H162.79v-9h21.74Z"
                    />
                    <polygon
                        className="cls-1"
                        points="76.95 32.97 76.92 8.43 49.56 8.43 49.56 0 41.52 0 41.52 24.23 49.56 16.18 68.91 16.18 68.91 32.97 76.95 32.97"
                    />
                    <polygon
                        className="cls-1"
                        points="231.7 32.97 231.67 8.47 196.26 8.43 196.26 24.23 204.31 16.18 223.65 16.18 223.65 32.97 231.7 32.97"
                    />
                    <polygon
                        className="cls-1"
                        points="108 25.25 88.66 25.25 88.66 8.47 80.61 8.47 80.65 33 108 33 108 41.43 116.05 41.43 116.05 17.21 108 25.25"
                    />
                    <polygon
                        className="cls-1"
                        points="151.06 16.21 151.06 8.47 124.78 8.47 119.73 13.51 119.74 33 127.78 33 127.78 16.21 151.06 16.21"
                    />
                    <polygon
                        className="cls-1"
                        points="236.56 9.18 238.19 9.18 238.19 13.76 238.97 13.76 238.97 9.18 240.61 9.18 240.61 8.47 236.56 8.47 236.56 9.18"
                    />
                    <polygon
                        className="cls-1"
                        points="245.65 8.47 244.09 12.23 242.54 8.47 241.5 8.47 241.5 13.76 242.28 13.76 242.28 9.8 243.91 13.76 244.27 13.76 245.91 9.8 245.91 13.76 246.69 13.76 246.69 8.47 245.65 8.47"
                    />
                </g>
            </g>
        </svg>
    );
};

export default Logo;
