import { Dialog, DialogContent, DialogTitle, Box } from "./";
import { IconButton } from "@mui/material";
import { XIcon } from "@heroicons/react/solid";

interface Props {
    open: boolean;
    title: string;
    onClose?: () => void;
    children: any;
}

const Component = ({ open, onClose, children, title }: Props): JSX.Element => {
    return (
        <Dialog open={open} maxWidth="xl">
            <DialogTitle sx={{
                fontSize: "24px",
                fontWeight: "bold",
                padding: "40px 40px 0 40px",
            }}>{title}</DialogTitle>
            { onClose && (
                <Box sx={{
                    position: "absolute",
                    top: "10px",
                    right: "10px",
                    width: "40px",
                    height: "40px",
                }}>
                    <IconButton onClick={onClose}>
                        <XIcon color="#000000" width={20} height={20} />
                    </IconButton>
                </Box>
            )}
            <DialogContent sx={{
                width: "700px",
                padding: "24px 40px 40px 40px",
            }}>{children}</DialogContent>
        </Dialog>
    );
};

export default Component;
