import { useContext, useEffect, useState } from "react";
import { Link as RouterLink, useNavigate, useParams } from "react-router-dom";
import CampaignContext from "../../../../../contexts/CampaignContext";
import { deleteRule, getRules, reorderRule, saveRule } from "../../../../../common/api";
import { Rule } from "../../../../../common/types";
import {
    Heading, Table, Breadcrumbs,
    Box,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    Typography,
    Link, Button, SimpleDialog,
    Switch, IconButton, Dot
} from "../../../../../components";
import * as React from "react";
import Editor from "./editor";
import { ChevronRightIcon, MenuAlt4Icon, PlusIcon, TrashIcon } from "@heroicons/react/solid";
import Draggable from "react-draggable";
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from "@mui/material";

const Page = () => {
    const { campaign } = useContext(CampaignContext);
    const [ rules, setRules ] = useState<Rule[] | undefined>();
    const [ rule, setRule ] = useState<Rule | false | undefined>(false);
    const { pid } = useParams();
    const navigate = useNavigate();
    const productionId = parseInt(pid!);
    const [ dragIndex, setDragIndex ] = useState<number | undefined>(undefined);
    const [ deleteRuleId, setDeleteRuleId ] = useState<number | undefined>();

    let dragging = false;

    const refreshRules = () => {
        if (!isNaN(productionId)) {
            getRules(productionId).then(setRules);
        }
    }

    const setActive = async (rule: Rule, active: boolean): Promise<void> => {
        try {
            await saveRule({
                id: rule.id,
                is_active: active
            });

            setRules(await getRules(productionId));
        } catch {

        }
    }

    useEffect(refreshRules, [ campaign, pid ]);

    return (
        <>
            <SimpleDialog
                open={rule !== false}
                title={rule ? `Update ${rule.name}` : "New display rule"}
                onClose={() => setRule(false)}>
                <Editor
                    onClose={() => {
                        setRule(false);
                        refreshRules();
                    }}
                    ruleId={(rule as Rule | undefined)?.id}
                />
            </SimpleDialog>

            <Dialog
                open={deleteRuleId !== undefined}
                onClose={() => setDeleteRuleId(undefined)}
            >
                <DialogTitle>Delete rule</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Are you sure you want to delete this rule?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setDeleteRuleId(undefined)}>Cancel</Button>
                    <Button onClick={async () => {
                        setDeleteRuleId(undefined);
                        await deleteRule(deleteRuleId!);
                        await refreshRules();
                    }}>Delete</Button>
                </DialogActions>
            </Dialog>

            <Breadcrumbs aria-label="breadcrumb">
                <Link underline="hover" color="inherit" to="/campaigns" component={RouterLink} >
                    Campaigns
                </Link>
                <Link underline="hover" color="inherit" to={`/campaigns/${campaign?.id}/productions`} component={RouterLink} >
                    Productions
                </Link>
                <Link underline="hover" color="inherit" to={`/campaigns/${campaign?.id}/productions/${pid}/designs`} component={RouterLink} >
                    Designs
                </Link>
                <Typography color="text.primary">Display Rules</Typography>
            </Breadcrumbs>

            <Heading>
                  Display Rules
            </Heading>

            <Box
                sx={{
                    background: "white",
                    minHeight: "200px",
                    padding: "24px",
                    border: "1px solid #EAEAEA",
                    borderRadius: "4px",
                }}
            >
                <Box sx={{ marginBottom: "40px" }}>
                    <Button
                        variant="contained"
                        sx={{
                            backgroundColor: "#00000014",
                            color: "#000000",
                            "&:hover": {
                                backgroundColor: "#00000030",
                                color: "#000000",
                            }
                        }}
                        onClick={() => navigate(`../productions/${pid}/designs`)}
                    >
                        Designs
                    </Button>

                    <Button
                        variant="contained"
                        sx={{
                            marginLeft: "10px",
                            backgroundColor: "#000000",
                            "&:hover": {
                                backgroundColor: "#000000"
                            }
                        }}
                    >
                        Display Rules
                    </Button>

                    <Button
                        variant="contained"
                        sx={{
                            float: "right",
                        }}
                        onClick={() => setRule(undefined)}
                        startIcon={<PlusIcon height={24} />}
                    >
                       Create
                    </Button>
                </Box>

                <Table
                    sx={{
                        "td, th": {
                            whiteSpace: "nowrap",
                        },
                        "td:last-child": {
                            width: "100%",
                        },
                        "thead > tr > th": {
                            fontWeight: 600,
                        },
                        "tbody > tr > td": {
                            fontWeight: 500,
                        },
                    }}
                >
                    <TableHead>
                        <TableRow>
                            <TableCell>Priority</TableCell>
                            <TableCell>Name</TableCell>
                            <TableCell>Design</TableCell>
                            <TableCell>Products triggered</TableCell>
                            <TableCell>Active</TableCell>
                            <TableCell>&nbsp;</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {rules?.map((rule, index) => {
                            return (
                                <Draggable
                                    onStart={() => {
                                        if (dragging) {
                                            setDragIndex(index);
                                        } else {
                                            return false;
                                        }
                                    }}
                                    onDrag={(_, {x, y}) => {
                                        setDragIndex(Math.trunc(index - (y + 34) / -67));
                                    }}
                                    onStop={(_, {x, y}) => {
                                        try {
                                            reorderRule(
                                                rule.id, 
                                                dragIndex! >= rules.length
                                                    ? rules[rules.length - 1].ordinal! + 1 
                                                    : rules[dragIndex! < 0 ? 0 : dragIndex!].ordinal!
                                            ).then(refreshRules);
                                        } catch (_) {

                                        }

                                        setDragIndex(undefined);
                                    }}
                                    position={{ x: 0, y: 0 }}
                                >
                                    <TableRow
                                        key={rule.id}
                                        sx={{
                                            borderTop: dragIndex === index ? "2px solid red" : undefined
                                        }}
                                    >
                                        <TableCell align="right">{index + 1}</TableCell>
                                        <TableCell>
                                            <Link sx={{ cursor: "pointer" }} onClick={() => setRule(rule)}>
                                                {rule.name}
                                            </Link>
                                        </TableCell>
                                        <TableCell>
                                            {rule.design_name
                                                ? (
                                                    <Link
                                                        underline="hover"
                                                        to={`/campaigns/${rule.campaign_id}/productions/${productionId}/designs/${rule.design_id}`}
                                                        component={RouterLink}>
                                                        {rule.design_name}
                                                    </Link>
                                                )
                                                : "-"
                                            }
                                        </TableCell>
                                        <TableCell align="right">
                                            {rule.num_matches}
                                        </TableCell>
                                        <TableCell>
                                            <Switch checked={rule.is_active} onChange={e => setActive(rule, e.target.checked)} />
                                        </TableCell>
                                        <TableCell align="right">
                                            <IconButton
                                                sx={{ background: "#FAFAFA" }}
                                                onMouseDown={() => dragging = true}
                                                onMouseUp={() => dragging = false}
                                            >
                                                <MenuAlt4Icon color="black" height={20} />
                                            </IconButton>
                                            <IconButton
                                                sx={{ background: "#FAFAFA", marginLeft: "16px" }}
                                                onClick={() => setDeleteRuleId(rule?.id)}>
                                                <TrashIcon color="black" height={20} />
                                            </IconButton>
                                            <IconButton
                                                sx={{ background: "#FAFAFA", marginLeft: "16px" }}
                                                onClick={() => setRule(rule)}>
                                                <ChevronRightIcon color="black" height={20} />
                                            </IconButton>
                                        </TableCell>
                                    </TableRow>
                                </Draggable>
                            )
                        })}
                    </TableBody>
                </Table>
            </Box>
        </>
    );
};

export default Page;
