import { useContext, useEffect, useState } from "react";
import { Link as RouterLink } from "react-router-dom";
import CampaignContext from "../../../contexts/CampaignContext";
import { getProductions, saveProduction } from "../../../common/api";
import { Production } from "../../../common/types";
import {
    Heading, Table, Breadcrumbs,
    Box,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    Typography,
    Link, IconButton, SimpleDialog
} from "../../../components";
import { ChevronRightIcon, CogIcon } from "@heroicons/react/solid";
import * as React from "react";
import Editor from "./Editor";

const Page = () => {
    const { campaign } = useContext(CampaignContext);
    const [ productions, setProductions ] = useState<Production[] | undefined>();
    const [ production, setProduction ] = useState<Production | undefined>();

    useEffect(() => {
        if (campaign) {
            getProductions(campaign.id).then(setProductions);
        }
    }, [ campaign ]);

    return (
        <>
            <SimpleDialog
                open={production !== undefined}
                title={`Edit ${production?.name}`}
                onClose={() => setProduction(undefined)}>
                <Editor
                    production={production}
                    onUpdate={async production => {
                        await saveProduction(production);
                        await setProduction(undefined);
                        setProductions(await getProductions(campaign!.id));
                    }}/>
            </SimpleDialog>

            <Breadcrumbs aria-label="breadcrumb">
                <Link underline="hover" color="inherit" to="/campaigns" component={RouterLink} >
                    Campaigns
                </Link>
                <Typography color="text.primary">Productions</Typography>
            </Breadcrumbs>

            <Heading>
                Productions
            </Heading>

            <Box
                sx={{
                    background: "white",
                    padding: "24px",
                    border: "1px solid #EAEAEA",
                    borderRadius: "4px",
                }}
            >
                <Table
                    sx={{
                        "thead > tr > th": {
                            fontWeight: 600,
                        },
                        "tbody > tr > td": {
                            fontWeight: 500,
                        },
                    }}
                >
                    <TableHead>
                        <TableRow>
                            <TableCell>Name</TableCell>
                            <TableCell>File name</TableCell>
                            <TableCell>Resolution</TableCell>
                            <TableCell>&nbsp;</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {productions?.map((production) => (
                            <TableRow key={production.id}>
                                <TableCell width={200}>{production.name}</TableCell>
                                <TableCell width={200}>{production.base_filename}</TableCell>
                                <TableCell width={200}>{production.width} x {production.height}</TableCell>
                                <TableCell align="right">
                                    <IconButton
                                        sx={{ background: "#FAFAFA", marginLeft: "8px" }}
                                        onClick={() => setProduction(production)}>
                                        <CogIcon color="black" height={20} />
                                    </IconButton>
                                    <IconButton
                                        sx={{ background: "#FAFAFA", marginLeft: "8px" }}
                                        component={RouterLink}
                                        to={`${production.id}/designs`}>
                                        <ChevronRightIcon color="black" height={20} />
                                    </IconButton>
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </Box>
        </>
    );
};

export default Page;
