import {
    Campaign,
    Customer,
    DashboardDetails,
    Design, LoginResult,
    Product, Production, QueryAggResult,
    Rule, RuleIdentifier, RuleQueryCount,
    RuleResponse,
    Template
} from "./types";
import Login from "../pages/login";

export const baseUrl = "/api/v1/";

async function unwrap<T>(response: Response): Promise<T | undefined> {
    if (response.status === 204) {
        return undefined;
    }

    if (response.status >= 200 && response.status < 300) {
        return await response.json();
    }

    throw new Error(`Invalid response status ${response.status}`);
}

export async function get<T>(url: string): Promise<T | undefined> {
    return await unwrap(
        await fetch(`${baseUrl}${url}`, {
            method: "GET",
        })
    );
}

export async function del<T>(url: string): Promise<T | undefined> {
    return await unwrap(
        await fetch(`${baseUrl}${url}`, {
            method: "DELETE",
        })
    );
}

export async function put<T, R>(
    url: string,
    value: T
): Promise<R | undefined> {
    return await unwrap(
        await fetch(`${baseUrl}${url}`, {
            method: "PUT",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify(value),
        })
    );
}

export async function post<T, R>(
    url: string,
    value: T
): Promise<R | undefined> {
    return await unwrap(
        await fetch(`${baseUrl}${url}`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify(value),
        })
    );
}

export async function patch<T, R>(
    url: string,
    value: T
): Promise<R | undefined> {
    return await unwrap(
        await fetch(`${baseUrl}${url}`, {
            method: "PATCH",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify(value),
        })
    );
}

export const login = async (username: string, password: string): Promise<LoginResult> =>
    (await post("auth/login", {
        username: username,
        password: password,
    }) as LoginResult);

export const logout = (): Promise<void> => post("auth/logout", {});

export const getCurrentLogin = async (): Promise<LoginResult | undefined> => {
    try {
        return await get("auth/login") as LoginResult;
    } catch (e: any) {
        return undefined;
    }
};

export const createDesign = async (productionId: number, templateId: number, name: string): Promise<Design> => {
    return (await post(`productions/${productionId}/designs`, {
        template_id: templateId,
        name
    })) as Design
}

export const saveDesign = async (design: Design): Promise<Design> => {
    return (await patch(`designs/${design.id}`, design)) as Design
}

export const uploadFile = async (campaignId: number, file: File): Promise<any> => {
    const data = new FormData();
    data.append("file", file);

    return await unwrap(await fetch(`${baseUrl}campaigns/${campaignId}/files`, {
        method: "POST",
        body: data,
    }));
}

export const getDashboardDetails = async (id: number): Promise<DashboardDetails> =>
    await get(`campaigns/${id}/dashboard`) as DashboardDetails;

export const getProducts = async (id: number, limit?: number): Promise<Product[]> =>
    (await get(`campaigns/${id}/products`) as any)?.products as Product[];

export const getProductions = async (id: number): Promise<Production[]> =>
    (await get(`campaigns/${id}/productions`)) as Production[];

export const getProduction = async (productionId: number): Promise<Production> =>
    (await get(`productions/${productionId}`)) as Production;

export const saveProduction = async (production: Production): Promise<Production> =>
    (await patch(`productions/${production.id}`, production)) as Production;

export const getIdentifiers = async (): Promise<Record<string, RuleIdentifier>> =>
    (await get("rules/identifiers")) as Record<string, RuleIdentifier>;

export const queryAgg = async (productionId: number, identifier: string): Promise<QueryAggResult[]> =>
    (await get(`productions/${productionId}/rules/query-agg?${new URLSearchParams({ identifier })}`)) as QueryAggResult[];

export const getRules = async (productionId: number): Promise<Rule[]> =>
    (await get(`productions/${productionId}/rules`) as any) as Rule[];

export const getRule = async (id: number): Promise<Rule | undefined> =>
    (await get(`rules/${id}`) as any) as Rule | undefined;

export const reorderRule = async (id: number, ordinal: number): Promise<void> =>
    (await put(`rules/${id}/ordinal`, { ordinal }));

export const countRules = async (productionId: number, expression: string): Promise<RuleQueryCount> =>
    (await get(`productions/${productionId}/rules/query-count?${new URLSearchParams({ expression })}`) as any) as RuleQueryCount;

export const saveRule = async (rule: Rule): Promise<RuleResponse> =>
    patch(`rules/${rule.id}`, rule) as Promise<RuleResponse>;

export const deleteRule = async (ruleId: number): Promise<void> =>
    del(`rules/${ruleId}`) as Promise<void>;

export const createRule = async(productionId: number, designId: number, name: string, expression: string): Promise<Rule> =>
    (await post(`productions/${productionId}/rules`, {
        design_id: designId,
        name,
        expression
    })) as Rule;

export async function getCampaigns(): Promise<Campaign[]> {
    return (await get("campaigns")) as Campaign[];
}

export async function getDesigns(productionId: number): Promise<Design[]> {
    return (await get(`productions/${productionId}/designs`)) as Design[];
}

export async function getDesign(id: number): Promise<Design> {
    return (await get(`designs/${id}`)) as Design;
}

export async function getTemplates(id: number): Promise<Template[]> {
    return (await get(`campaigns/${id}/templates`)) as Template[];
}
