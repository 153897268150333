// Generated from Prl.g4 by ANTLR 4.9.3
// jshint ignore: start
import antlr4 from "antlr4";
import PrlVisitor from "./PrlVisitor.js";


const serializedATN = [ "\u0003\u608b\ua72a\u8133\ub9ed\u417c\u3be7\u7786",
    "\u5964\u0003\u0013D\u0004\u0002\t\u0002\u0004\u0003\t\u0003\u0004\u0004",
    "\t\u0004\u0003\u0002\u0003\u0002\u0003\u0002\u0003\u0003\u0003\u0003",
    "\u0003\u0003\u0003\u0003\u0003\u0003\u0003\u0003\u0003\u0003\u0003\u0003",
    "\u0003\u0003\u0003\u0003\u0003\u0003\u0003\u0003\u0003\u0003\u0003\u0003",
    "\u0003\u0003\u0003\u0003\u0003\u0003\u0003\u0003\u0003\u0003\u0003\u0003",
    "\u0003\u0003\u0003\u0003\u0003\u0003\u0003\u0003\u0003\u0003\u0003\u0003",
    "\u0007\u0003&\n\u0003\f\u0003\u000e\u0003)\u000b\u0003\u0003\u0003\u0003",
    "\u0003\u0003\u0003\u0003\u0003\u0003\u0003\u0003\u0003\u0003\u0003\u0003",
    "\u0003\u0003\u0003\u0003\u0003\u0005\u00035\n\u0003\u0003\u0003\u0003",
    "\u0003\u0003\u0003\u0003\u0003\u0003\u0003\u0003\u0003\u0007\u0003=",
    "\n\u0003\f\u0003\u000e\u0003@\u000b\u0003\u0003\u0004\u0003\u0004\u0003",
    "\u0004\u0002\u0003\u0004\u0005\u0002\u0004\u0006\u0002\u0003\u0003\u0002",
    "\u000f\u0011\u0002L\u0002\b\u0003\u0002\u0002\u0002\u00044\u0003\u0002",
    "\u0002\u0002\u0006A\u0003\u0002\u0002\u0002\b\t\u0005\u0004\u0003\u0002",
    "\t\n\u0007\u0002\u0002\u0003\n\u0003\u0003\u0002\u0002\u0002\u000b\f",
    "\b\u0003\u0001\u0002\f\r\u0007\u0006\u0002\u0002\r5\u0005\u0004\u0003",
    "\u000e\u000e\u000f\u0007\u000e\u0002\u0002\u000f\u0010\u0007\u0005\u0002",
    "\u0002\u00105\u0005\u0006\u0004\u0002\u0011\u0012\u0007\u000e\u0002",
    "\u0002\u0012\u0013\u0007\u0007\u0002\u0002\u0013\u0014\u0007\u000b\u0002",
    "\u0002\u0014\u0015\u0005\u0006\u0004\u0002\u0015\u0016\u0007\u0004\u0002",
    "\u0002\u0016\u0017\u0005\u0006\u0004\u0002\u0017\u0018\u0007\f\u0002",
    "\u0002\u00185\u0003\u0002\u0002\u0002\u0019\u001a\u0007\u000e\u0002",
    "\u0002\u001a\u001b\u0007\n\u0002\u0002\u001b5\u0007\u0011\u0002\u0002",
    "\u001c\u001d\u0007\u000e\u0002\u0002\u001d\u001e\u0007\t\u0002\u0002",
    "\u001e5\u0007\u0011\u0002\u0002\u001f \u0007\u000e\u0002\u0002 !\u0007",
    "\b\u0002\u0002!\'\u0007\u000b\u0002\u0002\"#\u0005\u0006\u0004\u0002",
    "#$\u0007\r\u0002\u0002$&\u0003\u0002\u0002\u0002%\"\u0003\u0002\u0002",
    "\u0002&)\u0003\u0002\u0002\u0002\'%\u0003\u0002\u0002\u0002\'(\u0003",
    "\u0002\u0002\u0002(*\u0003\u0002\u0002\u0002)\'\u0003\u0002\u0002\u0002",
    "*+\u0005\u0006\u0004\u0002+,\u0007\f\u0002\u0002,5\u0003\u0002\u0002",
    "\u0002-.\u0007\u000b\u0002\u0002./\u0005\u0004\u0003\u0002/0\u0007\f",
    "\u0002\u000205\u0003\u0002\u0002\u000215\u0007\u000e\u0002\u000225\u0007",
    "\u0011\u0002\u000235\u0005\u0006\u0004\u00024\u000b\u0003\u0002\u0002",
    "\u00024\u000e\u0003\u0002\u0002\u00024\u0011\u0003\u0002\u0002\u0002",
    "4\u0019\u0003\u0002\u0002\u00024\u001c\u0003\u0002\u0002\u00024\u001f",
    "\u0003\u0002\u0002\u00024-\u0003\u0002\u0002\u000241\u0003\u0002\u0002",
    "\u000242\u0003\u0002\u0002\u000243\u0003\u0002\u0002\u00025>\u0003\u0002",
    "\u0002\u000267\f\u000b\u0002\u000278\u0007\u0004\u0002\u00028=\u0005",
    "\u0004\u0003\f9:\f\n\u0002\u0002:;\u0007\u0003\u0002\u0002;=\u0005\u0004",
    "\u0003\u000b<6\u0003\u0002\u0002\u0002<9\u0003\u0002\u0002\u0002=@\u0003",
    "\u0002\u0002\u0002><\u0003\u0002\u0002\u0002>?\u0003\u0002\u0002\u0002",
    "?\u0005\u0003\u0002\u0002\u0002@>\u0003\u0002\u0002\u0002AB\t\u0002",
    "\u0002\u0002B\u0007\u0003\u0002\u0002\u0002\u0006\'4<>" ].join("");


const atn = new antlr4.atn.ATNDeserializer().deserialize(serializedATN);

const decisionsToDFA = atn.decisionToState.map( (ds, index) => new antlr4.dfa.DFA(ds, index) );

const sharedContextCache = new antlr4.PredictionContextCache();

export default class PrlParser extends antlr4.Parser {

    static grammarFileName = "Prl.g4";
    static literalNames = [ null, "'or'", "'and'", "'eq'", "'not'", "'between'", 
        "'in'", "'contains'", "'starts-with'", "'('", 
        "')'", "','" ];
    static symbolicNames = [ null, "OR", "AND", "EQ", "NOT", "BETWEEN", 
        "IN", "CONTAINS", "STARTS", "OPAR", "CPAR", 
        "COMMA", "IDENTIFIER", "INT", "FLOAT", "STRING", 
        "SPACE", "OTHER" ];
    static ruleNames = [ "query", "expression", "literal" ];

    constructor(input) {
        super(input);
        this._interp = new antlr4.atn.ParserATNSimulator(this, atn, decisionsToDFA, sharedContextCache);
        this.ruleNames = PrlParser.ruleNames;
        this.literalNames = PrlParser.literalNames;
        this.symbolicNames = PrlParser.symbolicNames;
    }

    get atn() {
        return atn;
    }

    sempred(localctx, ruleIndex, predIndex) {
    	switch(ruleIndex) {
    	case 1:
    	    		return this.expression_sempred(localctx, predIndex);
        default:
            throw "No predicate with index:" + ruleIndex;
        }
    }

    expression_sempred(localctx, predIndex) {
    	switch(predIndex) {
    		case 0:
    			return this.precpred(this._ctx, 9);
    		case 1:
    			return this.precpred(this._ctx, 8);
    		default:
    			throw "No predicate with index:" + predIndex;
    	}
    };




    query() {
	    let localctx = new QueryContext(this, this._ctx, this.state);
	    this.enterRule(localctx, 0, PrlParser.RULE_query);
	    try {
	        this.enterOuterAlt(localctx, 1);
	        this.state = 6;
	        this.expression(0);
	        this.state = 7;
	        this.match(PrlParser.EOF);
	    } catch (re) {
	    	if(re instanceof antlr4.error.RecognitionException) {
		        localctx.exception = re;
		        this._errHandler.reportError(this, re);
		        this._errHandler.recover(this, re);
		    } else {
		    	throw re;
		    }
	    } finally {
	        this.exitRule();
	    }
	    return localctx;
    }


    expression(_p) {
        if(_p===undefined) {
		    _p = 0;
        }
	    const _parentctx = this._ctx;
	    const _parentState = this.state;
	    let localctx = new ExpressionContext(this, this._ctx, _parentState);
	    let _prevctx = localctx;
	    const _startState = 2;
	    this.enterRecursionRule(localctx, 2, PrlParser.RULE_expression, _p);
	    try {
	        this.enterOuterAlt(localctx, 1);
	        this.state = 50;
	        this._errHandler.sync(this);
	        var la_ = this._interp.adaptivePredict(this._input,1,this._ctx);
	        switch(la_) {
	        case 1:
	            localctx = new NotExpressionContext(this, localctx);
	            this._ctx = localctx;
	            _prevctx = localctx;

	            this.state = 10;
	            this.match(PrlParser.NOT);
	            this.state = 11;
	            this.expression(12);
	            break;

	        case 2:
	            localctx = new EqualityExpressionContext(this, localctx);
	            this._ctx = localctx;
	            _prevctx = localctx;
	            this.state = 12;
	            this.match(PrlParser.IDENTIFIER);
	            this.state = 13;
	            this.match(PrlParser.EQ);
	            this.state = 14;
	            this.literal();
	            break;

	        case 3:
	            localctx = new BetweenExpressionContext(this, localctx);
	            this._ctx = localctx;
	            _prevctx = localctx;
	            this.state = 15;
	            this.match(PrlParser.IDENTIFIER);
	            this.state = 16;
	            this.match(PrlParser.BETWEEN);
	            this.state = 17;
	            this.match(PrlParser.OPAR);
	            this.state = 18;
	            this.literal();
	            this.state = 19;
	            this.match(PrlParser.AND);
	            this.state = 20;
	            this.literal();
	            this.state = 21;
	            this.match(PrlParser.CPAR);
	            break;

	        case 4:
	            localctx = new StartsWithExpressionContext(this, localctx);
	            this._ctx = localctx;
	            _prevctx = localctx;
	            this.state = 23;
	            this.match(PrlParser.IDENTIFIER);
	            this.state = 24;
	            this.match(PrlParser.STARTS);
	            this.state = 25;
	            this.match(PrlParser.STRING);
	            break;

	        case 5:
	            localctx = new ContainsExpressionContext(this, localctx);
	            this._ctx = localctx;
	            _prevctx = localctx;
	            this.state = 26;
	            this.match(PrlParser.IDENTIFIER);
	            this.state = 27;
	            this.match(PrlParser.CONTAINS);
	            this.state = 28;
	            this.match(PrlParser.STRING);
	            break;

	        case 6:
	            localctx = new InExpressionContext(this, localctx);
	            this._ctx = localctx;
	            _prevctx = localctx;
	            this.state = 29;
	            this.match(PrlParser.IDENTIFIER);
	            this.state = 30;
	            this.match(PrlParser.IN);
	            this.state = 31;
	            this.match(PrlParser.OPAR);
	            this.state = 37;
	            this._errHandler.sync(this);
	            var _alt = this._interp.adaptivePredict(this._input,0,this._ctx)
	            while(_alt!=2 && _alt!=antlr4.atn.ATN.INVALID_ALT_NUMBER) {
	                if(_alt===1) {
	                    this.state = 32;
	                    this.literal();
	                    this.state = 33;
	                    this.match(PrlParser.COMMA); 
	                }
	                this.state = 39;
	                this._errHandler.sync(this);
	                _alt = this._interp.adaptivePredict(this._input,0,this._ctx);
	            }

	            this.state = 40;
	            this.literal();
	            this.state = 41;
	            this.match(PrlParser.CPAR);
	            break;

	        case 7:
	            localctx = new ParenthesizedExpressionContext(this, localctx);
	            this._ctx = localctx;
	            _prevctx = localctx;
	            this.state = 43;
	            this.match(PrlParser.OPAR);
	            this.state = 44;
	            this.expression(0);
	            this.state = 45;
	            this.match(PrlParser.CPAR);
	            break;

	        case 8:
	            localctx = new IdentifierExpressionContext(this, localctx);
	            this._ctx = localctx;
	            _prevctx = localctx;
	            this.state = 47;
	            this.match(PrlParser.IDENTIFIER);
	            break;

	        case 9:
	            localctx = new StringExpressionContext(this, localctx);
	            this._ctx = localctx;
	            _prevctx = localctx;
	            this.state = 48;
	            this.match(PrlParser.STRING);
	            break;

	        case 10:
	            localctx = new LiteralExpressionContext(this, localctx);
	            this._ctx = localctx;
	            _prevctx = localctx;
	            this.state = 49;
	            this.literal();
	            break;

	        }
	        this._ctx.stop = this._input.LT(-1);
	        this.state = 60;
	        this._errHandler.sync(this);
	        var _alt = this._interp.adaptivePredict(this._input,3,this._ctx)
	        while(_alt!=2 && _alt!=antlr4.atn.ATN.INVALID_ALT_NUMBER) {
	            if(_alt===1) {
	                if(this._parseListeners!==null) {
	                    this.triggerExitRuleEvent();
	                }
	                _prevctx = localctx;
	                this.state = 58;
	                this._errHandler.sync(this);
	                var la_ = this._interp.adaptivePredict(this._input,2,this._ctx);
	                switch(la_) {
	                case 1:
	                    localctx = new AndExpressionContext(this, new ExpressionContext(this, _parentctx, _parentState));
	                    this.pushNewRecursionContext(localctx, _startState, PrlParser.RULE_expression);
	                    this.state = 52;
	                    if (!( this.precpred(this._ctx, 9))) {
	                        throw new antlr4.error.FailedPredicateException(this, "this.precpred(this._ctx, 9)");
	                    }
	                    this.state = 53;
	                    this.match(PrlParser.AND);
	                    this.state = 54;
	                    this.expression(10);
	                    break;

	                case 2:
	                    localctx = new OrExpressionContext(this, new ExpressionContext(this, _parentctx, _parentState));
	                    this.pushNewRecursionContext(localctx, _startState, PrlParser.RULE_expression);
	                    this.state = 55;
	                    if (!( this.precpred(this._ctx, 8))) {
	                        throw new antlr4.error.FailedPredicateException(this, "this.precpred(this._ctx, 8)");
	                    }
	                    this.state = 56;
	                    this.match(PrlParser.OR);
	                    this.state = 57;
	                    this.expression(9);
	                    break;

	                } 
	            }
	            this.state = 62;
	            this._errHandler.sync(this);
	            _alt = this._interp.adaptivePredict(this._input,3,this._ctx);
	        }

	    } catch( error) {
	        if(error instanceof antlr4.error.RecognitionException) {
		        localctx.exception = error;
		        this._errHandler.reportError(this, error);
		        this._errHandler.recover(this, error);
		    } else {
		    	throw error;
		    }
	    } finally {
	        this.unrollRecursionContexts(_parentctx)
	    }
	    return localctx;
    }



    literal() {
	    let localctx = new LiteralContext(this, this._ctx, this.state);
	    this.enterRule(localctx, 4, PrlParser.RULE_literal);
	    var _la = 0; // Token type
	    try {
	        this.enterOuterAlt(localctx, 1);
	        this.state = 63;
	        _la = this._input.LA(1);
	        if(!((((_la) & ~0x1f) == 0 && ((1 << _la) & ((1 << PrlParser.INT) | (1 << PrlParser.FLOAT) | (1 << PrlParser.STRING))) !== 0))) {
	        this._errHandler.recoverInline(this);
	        }
	        else {
	        	this._errHandler.reportMatch(this);
	            this.consume();
	        }
	    } catch (re) {
	    	if(re instanceof antlr4.error.RecognitionException) {
		        localctx.exception = re;
		        this._errHandler.reportError(this, re);
		        this._errHandler.recover(this, re);
		    } else {
		    	throw re;
		    }
	    } finally {
	        this.exitRule();
	    }
	    return localctx;
    }


}

PrlParser.EOF = antlr4.Token.EOF;
PrlParser.OR = 1;
PrlParser.AND = 2;
PrlParser.EQ = 3;
PrlParser.NOT = 4;
PrlParser.BETWEEN = 5;
PrlParser.IN = 6;
PrlParser.CONTAINS = 7;
PrlParser.STARTS = 8;
PrlParser.OPAR = 9;
PrlParser.CPAR = 10;
PrlParser.COMMA = 11;
PrlParser.IDENTIFIER = 12;
PrlParser.INT = 13;
PrlParser.FLOAT = 14;
PrlParser.STRING = 15;
PrlParser.SPACE = 16;
PrlParser.OTHER = 17;

PrlParser.RULE_query = 0;
PrlParser.RULE_expression = 1;
PrlParser.RULE_literal = 2;

class QueryContext extends antlr4.ParserRuleContext {

    constructor(parser, parent, invokingState) {
        if(parent===undefined) {
            parent = null;
        }
        if(invokingState===undefined || invokingState===null) {
            invokingState = -1;
        }
        super(parent, invokingState);
        this.parser = parser;
        this.ruleIndex = PrlParser.RULE_query;
    }

    expression() {
	    return this.getTypedRuleContext(ExpressionContext,0);
    };

    EOF() {
	    return this.getToken(PrlParser.EOF, 0);
    };

    accept(visitor) {
	    if ( visitor instanceof PrlVisitor ) {
	        return visitor.visitQuery(this);
	    } else {
	        return visitor.visitChildren(this);
	    }
    }


}



class ExpressionContext extends antlr4.ParserRuleContext {

    constructor(parser, parent, invokingState) {
        if(parent===undefined) {
            parent = null;
        }
        if(invokingState===undefined || invokingState===null) {
            invokingState = -1;
        }
        super(parent, invokingState);
        this.parser = parser;
        this.ruleIndex = PrlParser.RULE_expression;
    }


	 
    copyFrom(ctx) {
        super.copyFrom(ctx);
    }

}


class ParenthesizedExpressionContext extends ExpressionContext {

    constructor(parser, ctx) {
        super(parser);
        super.copyFrom(ctx);
    }

    OPAR() {
	    return this.getToken(PrlParser.OPAR, 0);
    };

    expression() {
	    return this.getTypedRuleContext(ExpressionContext,0);
    };

    CPAR() {
	    return this.getToken(PrlParser.CPAR, 0);
    };

    accept(visitor) {
	    if ( visitor instanceof PrlVisitor ) {
	        return visitor.visitParenthesizedExpression(this);
	    } else {
	        return visitor.visitChildren(this);
	    }
    }


}

PrlParser.ParenthesizedExpressionContext = ParenthesizedExpressionContext;

class ContainsExpressionContext extends ExpressionContext {

    constructor(parser, ctx) {
        super(parser);
        super.copyFrom(ctx);
    }

    IDENTIFIER() {
	    return this.getToken(PrlParser.IDENTIFIER, 0);
    };

    CONTAINS() {
	    return this.getToken(PrlParser.CONTAINS, 0);
    };

    STRING() {
	    return this.getToken(PrlParser.STRING, 0);
    };

    accept(visitor) {
	    if ( visitor instanceof PrlVisitor ) {
	        return visitor.visitContainsExpression(this);
	    } else {
	        return visitor.visitChildren(this);
	    }
    }


}

PrlParser.ContainsExpressionContext = ContainsExpressionContext;

class AndExpressionContext extends ExpressionContext {

    constructor(parser, ctx) {
        super(parser);
        super.copyFrom(ctx);
    }

	expression = function(i) {
	    if(i===undefined) {
	        i = null;
	    }
	    if(i===null) {
	        return this.getTypedRuleContexts(ExpressionContext);
	    } else {
	        return this.getTypedRuleContext(ExpressionContext,i);
	    }
	};

	AND() {
	    return this.getToken(PrlParser.AND, 0);
	};

	accept(visitor) {
	    if ( visitor instanceof PrlVisitor ) {
	        return visitor.visitAndExpression(this);
	    } else {
	        return visitor.visitChildren(this);
	    }
	}


}

PrlParser.AndExpressionContext = AndExpressionContext;

class BetweenExpressionContext extends ExpressionContext {

    constructor(parser, ctx) {
        super(parser);
        super.copyFrom(ctx);
    }

    IDENTIFIER() {
	    return this.getToken(PrlParser.IDENTIFIER, 0);
    };

    BETWEEN() {
	    return this.getToken(PrlParser.BETWEEN, 0);
    };

    OPAR() {
	    return this.getToken(PrlParser.OPAR, 0);
    };

	literal = function(i) {
	    if(i===undefined) {
	        i = null;
	    }
	    if(i===null) {
	        return this.getTypedRuleContexts(LiteralContext);
	    } else {
	        return this.getTypedRuleContext(LiteralContext,i);
	    }
	};

	AND() {
	    return this.getToken(PrlParser.AND, 0);
	};

	CPAR() {
	    return this.getToken(PrlParser.CPAR, 0);
	};

	accept(visitor) {
	    if ( visitor instanceof PrlVisitor ) {
	        return visitor.visitBetweenExpression(this);
	    } else {
	        return visitor.visitChildren(this);
	    }
	}


}

PrlParser.BetweenExpressionContext = BetweenExpressionContext;

class InExpressionContext extends ExpressionContext {

    constructor(parser, ctx) {
        super(parser);
        super.copyFrom(ctx);
    }

    IDENTIFIER() {
	    return this.getToken(PrlParser.IDENTIFIER, 0);
    };

    IN() {
	    return this.getToken(PrlParser.IN, 0);
    };

    OPAR() {
	    return this.getToken(PrlParser.OPAR, 0);
    };

	literal = function(i) {
	    if(i===undefined) {
	        i = null;
	    }
	    if(i===null) {
	        return this.getTypedRuleContexts(LiteralContext);
	    } else {
	        return this.getTypedRuleContext(LiteralContext,i);
	    }
	};

	CPAR() {
	    return this.getToken(PrlParser.CPAR, 0);
	};

	COMMA = function(i) {
	    if(i===undefined) {
	        i = null;
	    }
	    if(i===null) {
	        return this.getTokens(PrlParser.COMMA);
	    } else {
	        return this.getToken(PrlParser.COMMA, i);
	    }
	};


	accept(visitor) {
	    if ( visitor instanceof PrlVisitor ) {
	        return visitor.visitInExpression(this);
	    } else {
	        return visitor.visitChildren(this);
	    }
	}


}

PrlParser.InExpressionContext = InExpressionContext;

class StringExpressionContext extends ExpressionContext {

    constructor(parser, ctx) {
        super(parser);
        super.copyFrom(ctx);
    }

    STRING() {
	    return this.getToken(PrlParser.STRING, 0);
    };

    accept(visitor) {
	    if ( visitor instanceof PrlVisitor ) {
	        return visitor.visitStringExpression(this);
	    } else {
	        return visitor.visitChildren(this);
	    }
    }


}

PrlParser.StringExpressionContext = StringExpressionContext;

class LiteralExpressionContext extends ExpressionContext {

    constructor(parser, ctx) {
        super(parser);
        super.copyFrom(ctx);
    }

    literal() {
	    return this.getTypedRuleContext(LiteralContext,0);
    };

    accept(visitor) {
	    if ( visitor instanceof PrlVisitor ) {
	        return visitor.visitLiteralExpression(this);
	    } else {
	        return visitor.visitChildren(this);
	    }
    }


}

PrlParser.LiteralExpressionContext = LiteralExpressionContext;

class NotExpressionContext extends ExpressionContext {

    constructor(parser, ctx) {
        super(parser);
        super.copyFrom(ctx);
    }

    NOT() {
	    return this.getToken(PrlParser.NOT, 0);
    };

    expression() {
	    return this.getTypedRuleContext(ExpressionContext,0);
    };

    accept(visitor) {
	    if ( visitor instanceof PrlVisitor ) {
	        return visitor.visitNotExpression(this);
	    } else {
	        return visitor.visitChildren(this);
	    }
    }


}

PrlParser.NotExpressionContext = NotExpressionContext;

class EqualityExpressionContext extends ExpressionContext {

    constructor(parser, ctx) {
        super(parser);
        super.copyFrom(ctx);
    }

    IDENTIFIER() {
	    return this.getToken(PrlParser.IDENTIFIER, 0);
    };

    EQ() {
	    return this.getToken(PrlParser.EQ, 0);
    };

    literal() {
	    return this.getTypedRuleContext(LiteralContext,0);
    };

    accept(visitor) {
	    if ( visitor instanceof PrlVisitor ) {
	        return visitor.visitEqualityExpression(this);
	    } else {
	        return visitor.visitChildren(this);
	    }
    }


}

PrlParser.EqualityExpressionContext = EqualityExpressionContext;

class StartsWithExpressionContext extends ExpressionContext {

    constructor(parser, ctx) {
        super(parser);
        super.copyFrom(ctx);
    }

    IDENTIFIER() {
	    return this.getToken(PrlParser.IDENTIFIER, 0);
    };

    STARTS() {
	    return this.getToken(PrlParser.STARTS, 0);
    };

    STRING() {
	    return this.getToken(PrlParser.STRING, 0);
    };

    accept(visitor) {
	    if ( visitor instanceof PrlVisitor ) {
	        return visitor.visitStartsWithExpression(this);
	    } else {
	        return visitor.visitChildren(this);
	    }
    }


}

PrlParser.StartsWithExpressionContext = StartsWithExpressionContext;

class OrExpressionContext extends ExpressionContext {

    constructor(parser, ctx) {
        super(parser);
        super.copyFrom(ctx);
    }

	expression = function(i) {
	    if(i===undefined) {
	        i = null;
	    }
	    if(i===null) {
	        return this.getTypedRuleContexts(ExpressionContext);
	    } else {
	        return this.getTypedRuleContext(ExpressionContext,i);
	    }
	};

	OR() {
	    return this.getToken(PrlParser.OR, 0);
	};

	accept(visitor) {
	    if ( visitor instanceof PrlVisitor ) {
	        return visitor.visitOrExpression(this);
	    } else {
	        return visitor.visitChildren(this);
	    }
	}


}

PrlParser.OrExpressionContext = OrExpressionContext;

class IdentifierExpressionContext extends ExpressionContext {

    constructor(parser, ctx) {
        super(parser);
        super.copyFrom(ctx);
    }

    IDENTIFIER() {
	    return this.getToken(PrlParser.IDENTIFIER, 0);
    };

    accept(visitor) {
	    if ( visitor instanceof PrlVisitor ) {
	        return visitor.visitIdentifierExpression(this);
	    } else {
	        return visitor.visitChildren(this);
	    }
    }


}

PrlParser.IdentifierExpressionContext = IdentifierExpressionContext;

class LiteralContext extends antlr4.ParserRuleContext {

    constructor(parser, parent, invokingState) {
        if(parent===undefined) {
            parent = null;
        }
        if(invokingState===undefined || invokingState===null) {
            invokingState = -1;
        }
        super(parent, invokingState);
        this.parser = parser;
        this.ruleIndex = PrlParser.RULE_literal;
    }

    STRING() {
	    return this.getToken(PrlParser.STRING, 0);
    };

    INT() {
	    return this.getToken(PrlParser.INT, 0);
    };

    FLOAT() {
	    return this.getToken(PrlParser.FLOAT, 0);
    };

    accept(visitor) {
	    if ( visitor instanceof PrlVisitor ) {
	        return visitor.visitLiteral(this);
	    } else {
	        return visitor.visitChildren(this);
	    }
    }


}




PrlParser.QueryContext = QueryContext; 
PrlParser.ExpressionContext = ExpressionContext; 
PrlParser.LiteralContext = LiteralContext; 

export {
    QueryContext, ExpressionContext, BetweenExpressionContext, IdentifierExpressionContext, ContainsExpressionContext,
    EqualityExpressionContext, ParenthesizedExpressionContext, InExpressionContext, LiteralExpressionContext,
    StringExpressionContext, AndExpressionContext, LiteralContext, StartsWithExpressionContext
};