import { styled, Typography } from "@mui/material";
import * as React from "react";

const Component = styled(Typography)(_ => ({
    fontSize: "32px",
    fontWeight: 800,
    margin: "0.8em 0",
    color: "#000",
}));

export default Component;
