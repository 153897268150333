import { Campaign } from "../common/types";
import React from "react";

type CampaignContextState = {
    campaign?: Campaign;
    activate: (campaign?: Campaign) => void;
};

const CampaignContext = React.createContext({} as CampaignContextState);

export default CampaignContext;
