import { Link as RouterLink } from "react-router-dom";
import {
    Heading, Table, TableBox, Breadcrumbs,
    Box, Button,
    Link, Pagination, TableBody, TableCell, TableHead, TableRow, TextField,
    Typography
} from "../../components";
import * as React from "react";
import { useContext, useEffect, useRef, useState } from "react";
import CampaignContext from "../../contexts/CampaignContext";
import { getProducts } from "../../common/api";
import { Product } from "../../common/types";
import { SearchIcon } from "@heroicons/react/solid";

const Page = () => {
    const { campaign } = useContext(CampaignContext);
    const [ products, setProducts ] = useState<Product[] | undefined>();
    const [ filter, setFilter ] = useState("");
    const [ searchString, setSearchString ] = useState("");
    const [ page, setPage ] = useState(1);
    const productsPerPage = 25;

    useEffect(() => {
        if (campaign) {
            getProducts(campaign.id).then(setProducts);
        }
    }, [ campaign ]);

    const filteredProducts = products?.filter(p => {
        const k = p.product_key.toLowerCase();
        const t = p.title.toLowerCase();
        return p.assets.length > 0 && (k.includes(filter) || t.includes(filter));
    }) ?? [];

    return (
        <Box sx={{ marginBottom: "20px" }}>
            <Breadcrumbs aria-label="breadcrumb">
                <Link underline="hover" color="inherit" to="/campaigns" component={RouterLink} >
                    Products
                </Link>
                <Typography color="text.primary">Dashboard</Typography>
            </Breadcrumbs>

            <Heading>Products</Heading>

            <TableBox>
                <Box sx={{
                    display: "flex",
                    flexDirection: "row"
                }}>
                    <TextField
                        InputLabelProps={{ shrink: false }}
                        InputProps={{ placeholder: "Search" }}
                        onChange={e => setSearchString(e.target.value as string)}
                        onKeyPress={(ev) => {
                            if (ev.key === "Enter") {
                                setFilter(searchString.toLowerCase());
                                ev.preventDefault();
                            }
                        }}
                    />
                    <Button
                        variant="contained"
                        sx={{
                            marginLeft: "8px"
                        }}
                        onClick={() => setFilter(searchString.toLowerCase())}
                    >
                        <SearchIcon height={20} />&nbsp;Search
                    </Button>
                </Box>

                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>Product Key</TableCell>
                            <TableCell>Product Title</TableCell>
                            <TableCell>Created</TableCell>
                            <TableCell>Modified</TableCell>
                            <TableCell>Click to download</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {filteredProducts
                            .slice(page * productsPerPage - productsPerPage, page * productsPerPage)
                            .map(product => {
                                const videos = product.assets.filter(asset => asset.file_type === "video");
                                const createdAt = product.created_at ? new Date(product.created_at) : null;
                                const modifiedAt = product.modified_at ? new Date(product.modified_at) : null;

                                return (
                                    <TableRow id={product.product_key}>
                                        <TableCell>{product.product_key}</TableCell>
                                        <TableCell>{product.title}</TableCell>
                                        <TableCell>{createdAt?.toLocaleDateString("sv-SE")}</TableCell>
                                        <TableCell>{modifiedAt?.toLocaleDateString("sv-SE")}</TableCell>
                                        <TableCell>
                                            {videos.length > 0 && <a target="_blank" href={videos[0].url}>Download</a>}
                                        </TableCell>
                                    </TableRow>
                                )
                            })}
                    </TableBody>
                </Table>
                <Pagination
                    sx={{ marginTop: "16px" }}
                    page={page}
                    count={Math.ceil((filteredProducts.length ?? 1) / productsPerPage)}
                    onChange={(e, page) => setPage(page)}
                />
            </TableBox>
        </Box>
    );
};

export default Page;
