import {
    Box,
    Button,
    FormControl,
    InputLabel,
    MenuItem,
    Select,
    TextField,
    Typography
} from "../../../../../components";
import { useContext, useEffect, useState } from "react";
import CampaignContext from "../../../../../contexts/CampaignContext";
import { Template } from "../../../../../common/types";
import { createDesign, getTemplates } from "../../../../../common/api";
import { useNavigate } from "react-router-dom";

const Page = () => {
    const { campaign } = useContext(CampaignContext);
    const [ templates, setTemplates ] = useState([] as Template[]);
    const [ template, setTemplate ] = useState(undefined as Template | undefined)
    const [ name, setName ] = useState("")
    const navigate = useNavigate();

    useEffect(() => {
        if (campaign) {
            getTemplates(campaign.id).then(setTemplates);
        }
    }, [ campaign ]);

    return (
        <Box>
            <Typography
                variant="h1"
                sx={{
                    fontSize: "32px",
                    fontWeight: 800,
                    margin: "0.8em 0",
                    color: "#172B4D",
                }}
                onClick={() => alert("nice")}
            >
                Create Design
            </Typography>

            <Box
                sx={{
                    borderRadius: "4px",
                    background: "white",
                    minHeight: "200px",
                    boxShadow: "1px 1px 2px 0px rgba(168,168,168,1)",
                    padding: "24px",
                }}
            >
                <TextField
                    label="Name"
                    fullWidth
                    margin="normal"
                    value={name}
                    onChange={e => setName(e.target.value)}
                />
                <FormControl fullWidth margin="normal">
                    <InputLabel>Template</InputLabel>
                    <Select
                        label="Template"
                        value={template?.id ?? 0}
                        onChange={e => setTemplate(templates.find(t => t.id === e.target.value))}
                    >
                        {templates.map(t => (
                            <MenuItem key={t.id} value={t.id}>{t.name}</MenuItem>
                        ))}
                    </Select>
                </FormControl>
                <Button
                    variant="contained"
                    onClick={() => {
                        createDesign(campaign!.id, template!.id, name).then(design => navigate(`/campaigns/${campaign?.id}/designs/${design.id}`));
                    }}
                >Create</Button>
            </Box>
        </Box>
    );
};

export default Page;
