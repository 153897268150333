import { useEffect, useState } from "react";
import { queryAgg } from "../../../../../common/api";
import { Box, MenuItem, Select, TextField } from "../../../../../components";
import PrlVisitor from "../../../../../prl/PrlVisitor";
import PrlParser, {
    BetweenExpressionContext,
    EqualityExpressionContext,
    ExpressionContext, LiteralContext,
    QueryContext,
    StartsWithExpressionContext
} from "../../../../../prl/PrlParser";
import antlr4 from "antlr4";
import PrlLexer from "../../../../../prl/PrlLexer";

interface EditorProps {
    condition?: Condition;
    productionId: number;
    onChange?: (condition: Condition) => void;
}

export abstract class Condition {
    identifier: string;

    protected constructor (identifier: string) {
        this.identifier = identifier;
    }

    abstract toPql(): string;
    abstract render(props: EditorProps): JSX.Element;
    abstract renderLogic(): JSX.Element;
}

export class OptionCondition extends Condition {
    value: string;

    constructor (identifier: string, value: string) {
        super(identifier);
        this.value = value;
    }

    toPql(): string {
        const { identifier, value } = this;
        return `${identifier} eq "${value.replaceAll(/\\"/g, "\\$0")}"`;
    }

    render({ onChange, productionId }: EditorProps): JSX.Element {
        const { identifier, value } = this;
        const [ options, setOptions ] = useState<[string, string][]>([]);

        useEffect(() => {
            queryAgg(productionId, identifier).then(result => setOptions(result.map(({ value }) => [ value, value ])));
        }, []);

        return (
            <Select
                fullWidth={true} value={options.length > 0 ? value : ""} onChange={(e) => {
                    onChange && onChange(new OptionCondition(identifier, e.target.value as string));
                }}>
                {options.map(([ id, name ]) => (
                    <MenuItem key={id} value={id}>{name}</MenuItem>
                ))}
            </Select>
        );
    };

    renderLogic (): JSX.Element {
        return (
            <>
                <Box sx={{
                    padding: "8px 35px",
                    background: "#D3E5FF",
                    border: "1px solid #FAFAFA",
                    borderRadius: "4px",
                    color: "#0070F3",
                    fontSize: "14px",
                    fontWeight: 600,
                }}>
                    {this.identifier}
                </Box>
                <Box sx={{
                    margin: "0 10px",
                    padding: "8px 35px",
                    border: "1px solid #EAEAEA",
                    borderRadius: "4px",
                    color: "#000000",
                    fontSize: "14px",
                    fontWeight: 600,
                }}>
                    is
                </Box>

                <Box sx={{
                    padding: "8px 35px",
                    background: "#FFEFCF",
                    border: "1px solid #FAFAFA",
                    borderRadius: "4px",
                    color: "#F5A623",
                    fontSize: "14px",
                    fontWeight: 600,
                }}>
                    {this.value}
                </Box>
            </>
        )
    }
}

export enum StringOp {
    Equals = "eq",
    Contains = "contains",
    StartsWith = "starts-with"
}

export class StringCondition extends Condition {
    op: StringOp;
    value: string;

    constructor (identifier: string, op: StringOp, value: string) {
        super(identifier);
        this.op = op;
        this.value = value;
    }

    toPql(): string {
        const { identifier, op, value } = this;
        return `${identifier} ${op} "${value.replaceAll(/\\"/g, "\\$0")}"`;
    }

    render({ onChange, productionId }: EditorProps): JSX.Element {
        const { identifier, value, op } = this;

        return (
            <Box sx={{
                display: "flex",
                width: "100%",
                marginRight: "-16px!important",
                "& > *": {
                    marginRight: "16px",
                }
            }}>
                <Select
                    sx={{ width: "200px" }} value={op} onChange={(e) => {
                        onChange && onChange(new StringCondition(identifier, e.target.value as StringOp, value));
                    }}>
                    <MenuItem value="eq">Equal to</MenuItem>
                    <MenuItem value="contains">Contains</MenuItem>
                    <MenuItem value="starts-with">Starts with</MenuItem>
                </Select>
                <TextField
                    sx={{ flexGrow: 1 }} value={value} onChange={(e) => {
                        onChange && onChange(new StringCondition(identifier, op, e.target.value as string));
                    }} />
            </Box>
        );
    }

    renderLogic (): JSX.Element {
        const opNames: Record<string, string> = {
            "eq": "equals",
            "starts-with": "starts with",
        };

        return (
            <>
                <Box sx={{
                    padding: "8px 35px",
                    background: "#D3E5FF",
                    border: "1px solid #FAFAFA",
                    borderRadius: "4px",
                    color: "#0070F3",
                    fontSize: "14px",
                    fontWeight: 600,
                }}>
                    {this.identifier}
                </Box>
                <Box sx={{
                    margin: "0 10px",
                    padding: "8px 35px",
                    border: "1px solid #EAEAEA",
                    borderRadius: "4px",
                    color: "#000000",
                    fontSize: "14px",
                    fontWeight: 600,
                }}>
                    {opNames[this.op] ?? this.op}
                </Box>

                <Box sx={{
                    padding: "8px 35px",
                    background: "#FFEFCF",
                    border: "1px solid #FAFAFA",
                    borderRadius: "4px",
                    color: "#F5A623",
                    fontSize: "14px",
                    fontWeight: 600,
                }}>
                    {this.value}
                </Box>
            </>
        )
    }

}

export enum FloatOp {
    GreaterThan = "gt",
    LessThan = "lt",
}

export class FloatCondition extends Condition {
    op: FloatOp;
    value: number;

    constructor (identifier: string, op: FloatOp, value: number) {
        super(identifier);
        this.op = op;
        this.value = value;
    }

    toPql(): string {
        const { identifier, op, value } = this;

        if (op === FloatOp.LessThan) {
            return `${identifier} between (0 and ${value})`;
        } else {
            return `${identifier} between (${value} and 99999999)`;
        }
    }

    render({ onChange, productionId }: EditorProps): JSX.Element {
        const { identifier, value, op } = this;

        return (
            <Box sx={{
                display: "flex",
                width: "100%",
                marginRight: "-16px!important",
                "& > *": {
                    marginRight: "16px",
                },
            }}>
                <Select
                    sx={{ flex: "0 1 200px" }}
                    value={op} onChange={(e) => {
                        onChange && onChange(new FloatCondition(identifier, e.target.value as FloatOp, value));
                    }}>
                    <MenuItem value="gt">Greater than</MenuItem>
                    <MenuItem value="lt">Less than</MenuItem>
                </Select>
                <TextField
                    sx={{ flex: "0 1 auto" }}
                    value={value} onChange={(e) => {
                        const value = parseFloat(e.target.value as string);
                        onChange && onChange(new FloatCondition(identifier, op, isNaN(value) ? 0 : value));
                    }} />
            </Box>
        );
    }

    renderLogic (): JSX.Element {
        const opNames: Record<string, string> = {
            "gt": "greater than",
            "lt": "less than",
        };

        return (
            <>
                <Box sx={{
                    padding: "8px 35px",
                    background: "#D3E5FF",
                    border: "1px solid #FAFAFA",
                    borderRadius: "4px",
                    color: "#0070F3",
                    fontSize: "14px",
                    fontWeight: 600,
                }}>
                    {this.identifier}
                </Box>
                <Box sx={{
                    margin: "0 10px",
                    padding: "8px 35px",
                    border: "1px solid #EAEAEA",
                    borderRadius: "4px",
                    color: "#000000",
                    fontSize: "14px",
                    fontWeight: 600,
                }}>
                    {opNames[this.op] ?? this.op}
                </Box>

                <Box sx={{
                    padding: "8px 35px",
                    background: "#FFEFCF",
                    border: "1px solid #FAFAFA",
                    borderRadius: "4px",
                    color: "#F5A623",
                    fontSize: "14px",
                    fontWeight: 600,
                }}>
                    {this.value}
                </Box>
            </>
        )
    }
}


export const Editor = ({ condition, ...rest }: EditorProps): JSX.Element | null => {
    return condition?.render(rest) ?? null;
}

const getText = (node: any): string => node.getText();

class ConditionVisitor extends PrlVisitor {
    visitQuery(ctx: QueryContext): any {
        return this.visitExpression(ctx.expression());
    }

    visitExpression(ctx: ExpressionContext): any {
        if (ctx instanceof EqualityExpressionContext) {
            return this.visitEqualityExpression(ctx);
        }

        if (ctx instanceof BetweenExpressionContext) {
            return this.visitBetweenExpression(ctx);
        }

        throw "";
    }

    visitStartsWithExpression(ctx: StartsWithExpressionContext): any {
        return super.visitStartsWithExpression(ctx);
    }

    visitLiteral(ctx: LiteralContext): any {
        let token = ctx.STRING();

        if (token) {
            return getText(token).slice(1, -1);
        }

        return parseFloat(getText(ctx.INT() || ctx.FLOAT()));
    }

    visitEqualityExpression(ctx: EqualityExpressionContext): any {
        const identifier = getText(ctx.IDENTIFIER());
        const value = ctx.literal().accept(this);

        if (typeof value === "string") {
            if (identifier === "vehicle.manufacturer") {
                return new OptionCondition(identifier, value);
            }
        }

        throw "";
    }

    visitBetweenExpression(ctx: BetweenExpressionContext): any {
        const identifier = getText(ctx.IDENTIFIER());
        const left = ctx.literal(0).accept(this);
        const right = ctx.literal(1).accept(this);

        if (left > 0 || right >= 99999999) {
            return new FloatCondition(identifier, FloatOp.GreaterThan, left);
        } else {
            return new FloatCondition(identifier, FloatOp.LessThan, right);
        }
    }
}

export function parseCondition(expression: string): Condition {
    const input = new antlr4.InputStream(expression);
    const lexer = new PrlLexer(input);
    const tokens = new antlr4.CommonTokenStream(lexer);
    const parser = new PrlParser(tokens);
    parser.buildParseTrees = true;

    return new ConditionVisitor().visitQuery(parser.query());
}
