import { styled, Table } from "@mui/material";
import * as React from "react";

const Component = styled(Table)(_ => ({
    "& .MuiTableCell-head": {
        fontSize: "14px",
        fontWeight: 600,
    },
    "& .MuiTableCell-body": {
        fontSize: "14px",
        fontWeight: 500,
        padding: "14px 16px 14px 16px",
    },
    "& .MuiTableRow-root:last-child .MuiTableCell-body": {
        borderBottom: "none",
    },
    "& .MuiTableCell-root:first-of-type": {
        paddingLeft: 0,
    },
    "& .MuiTableCell-root:last-child": {
        paddingRight: 0,
    }
}));

export default Component;
