import { SketchPicker } from "react-color";
import { useRef, useState } from "react";
import { Box, Popover } from "@mui/material";

type Props = {
    color: string;
    onChange: (color: string) => void;
};

const Component = ({ color, onChange }: Props) => {
    const [ visible, setVisible ] = useState(false);
    const inputRef = useRef(null);

    const style = color === undefined
        ? {
            backgroundColor: "#e5e5f7",
            opacity: "0.8",
            background: "repeating-linear-gradient(45deg, #8384a5, #8384a5 2px, #e5e5f7 2px, #e5e5f7 10px)",
        } : {
            backgroundColor: color,
        }

    return (
        <>
            <Box
                sx={{
                    display: "block",
                    padding: "11px",
                    backgroundColor: "#FFFFFF",
                    border: "1px solid rgba(0, 0, 0, 0.23)",
                    borderRadius: "4px",
                    cursor: "pointer",
                }}
            >
                <Box
                    sx={{
                        height: "34px",
                        width: "34px",
                        borderRadius: "17px",
                        ...style
                    }}
                    ref={inputRef}
                    onClick={() => setVisible(true)}
                ></Box>
            </Box>
            <Popover
                open={visible}
                onClose={() => setVisible(false)}
                anchorEl={inputRef.current}
                anchorOrigin={{ horizontal: "left", vertical: "bottom" }}
            >
                <SketchPicker
                    onChange={(c) => {
                        onChange(c.hex);
                    }}
                    color={color}
                />
            </Popover>
        </>
    );
};

export default Component;
