import { styled, Select } from "@mui/material";
import * as React from "react";

const Component = styled(Select)(_ => ({
    "& .MuiInputBase-input": {
        padding: "12px 12px 12px 12px",
        fontSize: "14px",
    },
}));

Component.defaultProps = {

}

export default Component;
