import { Production } from "../../../common/types";
import { useEffect, useState } from "react";
import { Box, MenuItem, TextField, Button } from "../../../components";

interface ComponentProps {
    production?: Production;
    onUpdate?: (production: Production) => void;
}

interface Resolution {
    width: number;
    height: number;
}

const RESOLUTIONS: Resolution[] = [
    { width: 800, height: 600 },
    { width: 1920, height: 1080 }
];

const Component = ({ production, onUpdate }: ComponentProps) => {
    const [ resolutionIndex, setResolutionIndex ] = useState(-1);
    const [ name, setName ] = useState("");

    useEffect(() => {
        if (production !== undefined) {
            setResolutionIndex(RESOLUTIONS.findIndex(({ width, height}) => width === production.width && height === production.height ));
            setName(production.name!);
        } else {
            setResolutionIndex(-1);
            setName("");
        }
    }, [ production ]);

    if (production === undefined) {
        return null;
    }

    return (
        <>
            <Box
                sx={{
                    padding: "24px",
                    border: "1px solid #EAEAEA",
                    borderRadius: "8px",
                    display: "grid",
                    gap: 2
                }}>
                <TextField fullWidth={true} label="Name" value={name} onChange={e => setName(e.target.value as any)} />

                <TextField fullWidth={true} select label="Resolution" value={resolutionIndex} onChange={e => setResolutionIndex(parseInt(e.target.value as any))}>
                    {RESOLUTIONS.map(({ width, height }, index) => (
                        <MenuItem key={`${index}`} value={`${index}`}>{width} x {height}</MenuItem>
                    ))}
                </TextField>
            </Box>
            <Box>
                <Button onClick={() => {
                    const { width, height } = RESOLUTIONS[resolutionIndex] ?? {};
                    const { id } = production;

                    if (production && width && height) {
                        onUpdate && onUpdate({ id, name, width, height });
                    }
                }}>Update</Button>
            </Box>
        </>
    )
};

export default Component;
